import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    margin:0,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    nav:false,
    autoplayHoverPause: true,
    items: 1,
    touchDrag: false,
    navText : ["<span class='ion-chevron-left'></span>","<span class='ion-chevron-right'></span>"],
    responsive:{
      0:{
        items:1,
        nav:false
      },
      600:{
        items:1,
        nav:false
      },
      1000:{
        items:1,
        nav:false
      }
    }
  };

  slidesStore = [
    {src: "assets/images/slider_01.jpg", subheading: "Welcome to DNAResource", desc: "Moving DNA Programs Forward", buttonText: "Featured Issue"},
    {src: "assets/images/banner-image.jpg", subheading: "DNA Hit of the Year", desc: "2021 Review Process", buttonText: "Learn More"}
  ];

  constructor() { 
  }

  ngOnInit() {
    
  }

}
