import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MapService } from 'src/app/services/map.service';
import { Location } from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModel } from '../../model/confirm.model';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  //color= '#ffffff';
  ConfirmModel = new ConfirmModel();
  statisticsData: any;
  addRegionForm: FormGroup;
disable = false;
  isedit = false;
  subRegion = [
    { subregion: "", sub_region_color: "#ffffff" }
  ]
  constructor(private fb: FormBuilder,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _MapService: MapService,
    private auth: AuthService,
    private _location: Location,
    private adminService: AdminService,
    private _toastr: ToastrService,
    private router: Router, ) {
    let token = this.storage.get('access_token');
    if (token == undefined || this.auth.decodeToken(token)['identity'][1] == 0) {
      this.router.navigateByUrl('/home');
    } else {
      this.addRegionForm = this.fb.group({
        //'id' : null,
        'region_id': localStorage.getItem('regionId'),
        'sub_region_id': localStorage.getItem('subRegionId'),
        'image': [null, Validators.required],
        'current_status': [null, Validators.required],
        'software_used': [null, Validators.required],
        'region_information': [null, Validators.required],
        'population': [null, Validators.required],
        'gdp': [null, Validators.required],
        'gdp_per_capita': [null, Validators.required],
        'government': [null, Validators.required],
        'convicted_testing': [null, Validators.required],
        'arrestee_testing': [null, Validators.required],
        'removal_convicted': [null, Validators.required],
        'removal_arrestee': [null, Validators.required],
        'convicted_sample': [null, Validators.required],
        'arrestee_sample': [null, Validators.required],
        'ct_switch': [null, Validators.required],
        'at_switch': [null, Validators.required],
        'rc_switch': [null, Validators.required],
        'ra_switch': [null, Validators.required],
        'cs_switch': [null, Validators.required],
        'as_switch': [null, Validators.required]
        // 'data' : [null, Validators.required],
        // 'agenciesresources' : [null, Validators.required],
        // 'resources' : [null, Validators.required],
        // 'media' : [null, Validators.required]

      });
     this.getStatistics();
    }
  }

  getStatistics() {
    this._MapService.getStatistics().subscribe((res) => {
      if (res['data'] != undefined && res['data'].length > 0) {
        debugger;
        this.statisticsData = res['data'][0];
        if (this.statisticsData['statistics_id'] > 0) {
          this.disable=false;
          localStorage.setItem("statisticID", this.statisticsData['statistics_id']);
          this.addRegionForm.controls['image'].setValue(this.statisticsData['image']);
          this.addRegionForm.controls['current_status'].setValue(this.statisticsData['current_status']);
          this.addRegionForm.controls['software_used'].setValue(this.statisticsData['software_used']);
          this.addRegionForm.controls['region_information'].setValue(this.statisticsData['region_information']);
          this.addRegionForm.controls['population'].setValue(this.statisticsData['population']);
          this.addRegionForm.controls['gdp'].setValue(this.statisticsData['gdp']);
          this.addRegionForm.controls['gdp_per_capita'].setValue(this.statisticsData['gdp_per_capita']);
          this.addRegionForm.controls['government'].setValue(this.statisticsData['government']);
          this.addRegionForm.controls['convicted_testing'].setValue(this.statisticsData['convicted_testing']);
          this.addRegionForm.controls['arrestee_testing'].setValue(this.statisticsData['arrestee_testing']);
          this.addRegionForm.controls['removal_convicted'].setValue(this.statisticsData['removal_convicted']);
          this.addRegionForm.controls['removal_arrestee'].setValue(this.statisticsData['removal_arrestee']);
          this.addRegionForm.controls['convicted_sample'].setValue(this.statisticsData['convicted_sample']);
          this.addRegionForm.controls['arrestee_sample'].setValue(this.statisticsData['arrestee_sample']);

          this.addRegionForm.controls['ct_switch'].setValue(this.statisticsData['ct_switch']);
          this.addRegionForm.controls['at_switch'].setValue(this.statisticsData['at_switch']);
          this.addRegionForm.controls['rc_switch'].setValue(this.statisticsData['rc_switch']);
          this.addRegionForm.controls['ra_switch'].setValue(this.statisticsData['ra_switch']);
          this.addRegionForm.controls['cs_switch'].setValue(this.statisticsData['cs_switch']);
          this.addRegionForm.controls['as_switch'].setValue(this.statisticsData['as_switch']);

          this.isedit = true;
        }

      }

    },
      (error) => {
        console.log(error);
        debugger;
        //  this.auth.logout();
      });
  }
  ngOnInit() {

  }
  // get sub_region(): any {return this.addRegionForm.get('sub_region')};
  addstatistics(formData: NgForm) {
    debugger;
    if (
      this.statisticsData != undefined && this.statisticsData != null &&
      this.statisticsData['statistics_id'] != undefined && this.statisticsData['statistics_id'] != null &&
      this.statisticsData['statistics_id'] > 0) {
      this.UpdateStatistics(formData);
    } else {
      this.InsertStatistics(formData);
    }

    //}
  }
  addSubRegion() {
    this.subRegion.push({ subregion: "", sub_region_color: "#CCCCCC" });
  }

  backClicked() {
    this._location.back();
  }

  InsertStatistics(formData: NgForm) {
    this.disable =true;
    this.adminService.AddStatistics(formData).subscribe((res) => {
      this._toastr.success(res.msg, "Success");
      this.getStatistics();
    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });
  }

  UpdateStatistics(formData: NgForm) {
    debugger;
    formData['statistics_id'] = this.statisticsData['statistics_id'];
    this.adminService.UpdateStatistics(formData).subscribe((res) => {
      this._toastr.success(res.msg, "Success");

    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });
  }

  RemoveData() {
    var _data = {
      "statistics_id": localStorage.getItem("statisticID")
    }
    debugger;
    this.adminService.DeleteDataPost(_data, 'reset_statistics').subscribe((res) => {
      this._toastr.success(res.msg, "Success");
      //this.backClicked();
    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });
  }

}
