import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatFormField } from '@angular/material/form-field';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AuthService } from '../../auth/auth.service';
import { MapService } from 'src/app/services/map.service';
import {ConfirmModel} from '../../model/confirm.model';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-adminsubregion',
  templateUrl: './manage-sub-region.component.html',
  styleUrls: ['./manage-sub-region.component.scss']
})
export class ManageSubRegionComponent implements OnInit {
  ConfirmModel = new ConfirmModel();
  displayedColumns: string[] = ['SubRegionID', 'sub_region', 'color', 'Statistics', 'Request','Action'];
  dataSource: any;
  Sub_Region_Data: any[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private adminService: AdminService,
    private _toastr: ToastrService,
    private _MapService: MapService,
    private auth: AuthService, ) {
    let token = this.storage.get('access_token');
    if (token == undefined || this.auth.decodeToken(token)['identity'][1] == 0) {
      this.router.navigateByUrl('/home');
    } else {
      this.auth.users().subscribe((res) => {
        this.getSubRegion();
      },
      (error) =>{
        this.auth.logout();
      });
  }
  }

  getSubRegion() {
    this.Sub_Region_Data = [];
    this._MapService.getSubRegions().subscribe((res) => {
      debugger;
      console.log(res);
      // this.Sub_Region_Data = res['data'];
      var i = 0;
      res['data'].forEach(element => {
        i = i + 1;
        element['SubRegionID'] = i
        this.Sub_Region_Data.push(element);
      });
      this.dataSource = new MatTableDataSource(this.Sub_Region_Data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    },
      (error) => {
        // this.auth.logout();
      });
  }
  ngOnInit() {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  addSubRegion() {
    localStorage.setItem("editSubRegion", "");
    this.router.navigate(['/admin-manage-sub-region']);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  viewStatistics(id) {
    localStorage.setItem("subRegionId", id);
    this.router.navigate(['/admin-statistics']);
  }

  viewRequest(id) {
    localStorage.setItem("subRegionId", id);
    this.router.navigate(['/request-statistics']);
  }

  editSubRegion(element){
    localStorage.setItem("editSubRegion", JSON.stringify(element));
    this.router.navigate(['/admin-manage-sub-region']);
  }

  RemoveData(data) {
    var _data = {
      "sub_region_id": data.sub_region_id
    }
    debugger;
    this.adminService.DeleteData(_data,'delete_sub_region').subscribe((res) => {
      this._toastr.success(res.msg,"Success");
      this.getSubRegion();
     // this.emptyData();
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }

}