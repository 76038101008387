import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MapService } from 'src/app/services/map.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import {ConfirmModel} from '../../model/confirm.model';
@Component({
  selector: 'app-add-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit {
ConfirmModel = new ConfirmModel();
  displayedColumns: string[] = ['year', 'crime_scene', 'suspect_offender', 'Action'];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  
  addRegionForm: FormGroup;
  yearly_data = [
    // { crime_scene: "", suspect_offender: "", year: "", data_id: "" }
  ];
  years = [];
  data_id = 0;
  date: Date = new Date();
  year = this.date.getFullYear();
    settings = {
        bigBanner: false,
        timePicker: false,
        format: 'yyyy',
        defaultOpen: false
    }
  constructor(private fb: FormBuilder,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _location: Location,
    private _MapService: MapService,
    private auth: AuthService,
    private adminService: AdminService,
    private _toastr: ToastrService,
    private router: Router, ) {
      this.createYear();
    let token = this.storage.get('access_token');
    if (token == undefined || this.auth.decodeToken(token)['identity'][1] == 0) {
      this.router.navigateByUrl('/home');
    } else {
      this.getYearlyData();
    }
  }

  getYearlyData() {
    var id = localStorage.getItem("statisticID");
      this._MapService.getYearlyData(id).subscribe((res) => {
        debugger;
        if (res['data']!=undefined) {
          this.yearly_data = res['data'];
          this.dataSource = new MatTableDataSource(this.yearly_data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
        console.log(res);
      },
        (error) => {
         // this.auth.logout();
        });
  }
  ngOnInit() {
    this.addRegionForm = this.fb.group({
      'statistics_id':localStorage.getItem('statisticID'),
      'crime_scene' : [''],
      'suspect_offender' : [''],
      'year' : [this.year]
    });
  }

  addYearlyData() {
    this.yearly_data.push({ crime_scene: "", suspect_offender: "", year: "", data_id: "" });
  }

  backClicked() {
    this._location.back();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  addNewData(formData: NgForm) {
    if(this.data_id==0) {
      this.InsertYearlyData(formData);
    } else {
      this.UpdateYearlyData(formData);
    }
    
  }

  InsertYearlyData(formData: NgForm) {
    this.adminService.AddYearlyData(formData).subscribe((res) => {
      this._toastr.success(res.msg,"Success");
      this.getYearlyData();
      this.emptyData();
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }

  UpdateYearlyData(formData: NgForm) {
    formData['data_id'] = this.data_id;
    this.adminService.UpdateYearlyData(formData).subscribe((res) => {
      this._toastr.success(res.msg,"Success");
      this.getYearlyData();
     // this.emptyData();
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }


  createYear() {
    for(var i = 1900; i<=2099;i++) {
      this.years.push(i);
    }
  }

  EditData(data) {
    this.data_id = data.data_id;
    this.addRegionForm.controls['crime_scene'].setValue(data.crime_scene);
    this.addRegionForm.controls['suspect_offender'].setValue(data.suspect_offender);
    this.addRegionForm.controls['year'].setValue(data.year);
  }

  emptyData(){
    this.data_id = 0;
    this.addRegionForm.controls['crime_scene'].setValue("");
      this.addRegionForm.controls['suspect_offender'].setValue("");
      this.addRegionForm.controls['year'].setValue("");
  }

  RemoveData(data) {
    var _data = {
      "data_id": data.data_id
    }
    debugger;
    this.adminService.DeleteData(_data,'delete_data').subscribe((res) => {
      this._toastr.success(res.msg,"Success");
      this.getYearlyData();
     // this.emptyData();
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
