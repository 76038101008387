import { Component, OnInit, Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../login/login.component';
import { AuthService } from '../auth/auth.service';
import { AdminService } from '../services/admin.service';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
isDesktop = true;
  is_login = false;
  is_admin = 0;
  display_text = null;
  link = null;
  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private modalService: NgbModal,
    private auth: AuthService,
    private adminService: AdminService,
    private deviceService: DeviceDetectorService
  ) {

    let token = this.storage.get('access_token');
    if (token != undefined && this.auth.decodeToken(token)) {
      this.is_login = true;
      if (this.auth.decodeToken(token)['identity'][1] == 1)
        this.is_admin = 1;
    } else {
      this.is_login = false;
    }
  }

  ngOnInit() {
    this.getFeatured();
    debugger;
   this.isDesktop = this.deviceService.isDesktop();
  }

  open() {
    this.modalService.open(LoginComponent);
  }

  logout() {
    this.auth.logout();
  }

  getFeatured() {
    this.adminService.GenericGetDataMethod("", "featured").subscribe((res) => {
      debugger;
      // this._toastr.success(res.msg,"Success");
      if (res != null) {
        this.display_text = res.data[0].display_text;
        this.link = res.data[0].link;
      }
    },
      (error) => {
        //  this._toastr.error("Error Occur while getting featured text","Error");
      });
  }

  scroll(el: HTMLElement) {
    var data = document.getElementById('targetContact');
    data.scrollIntoView();
  }
}
