import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import {Md5} from "md5-typescript";
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import {environment} from '../../environments/environment'
@Injectable({
  providedIn: 'root'
})

export class MapService {

  baseUrl =  environment.URL;

  constructor(public http: HttpClient,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    ) { }

  
  headers = new HttpHeaders({ 
    'Content-Type' : 'application/json',
    'Authorization' : 'Bearer ' + this.storage.get('access_token') 
  });
  

  getRegions(){
    console.log(this.storage.get('access_token') );
    debugger;
    return this.http.get( this.baseUrl + 'region', {
      headers: this.headers
    });
  }

  getAdminRegions(){
    console.log(this.storage.get('access_token') );
    debugger;
    return this.http.get( this.baseUrl + 'region', {
      headers: this.headers
    });
  }

  getStatistics(){
    var params = {
      'region_id' : localStorage.getItem("regionId"),
      'sub_region_id': localStorage.getItem("subRegionId")
    };
    console.log(params);
    return this.http.post<any>( this.baseUrl + 'statistics', params, {
      headers: this.headers
    });
  }
 
  getAgencies(id){
    var params = {
      'statistics_id' : id
    };
    console.log(params);
    return this.http.post<any>( this.baseUrl + 'responsible_agencies', params, {
      headers: this.headers
    });
  }

  getResources(id){
    var params = {
      'statistics_id' : id
    };
    console.log(params);
    return this.http.post<any>( this.baseUrl + 'additional_resources', params, {
      headers: this.headers
    });
  }

  getYearlyData(id){
    var params = {
      'statistics_id' : id
    };
    console.log(params);
    return this.http.post<any>( this.baseUrl + 'yearly_data', params, {
      headers: this.headers
    });
  }

  getMedia(id){
    var params = {
      'statistics_id' : id
    };
    console.log(params);
    return this.http.post<any>( this.baseUrl + 'media', params, {
      headers: this.headers
    });
  }

  getSubRegions() {
    var params = {
      'region_id' : localStorage.getItem("regionId")
    };
    return this.http.post<any>( this.baseUrl + 'sub_region', params, {
      headers: this.headers
    });
  }

  getData() {
    var params = {
      'region_id' : localStorage.getItem("regionId"),
      'sub_region_id': localStorage.getItem("subRegionId")
    };
    console.log(params);
    return this.http.post<any>( this.baseUrl + 'data', params, {
      headers: this.headers
    });
  }

  decodeToken(token: string = '') {
    
    const parts = token.split('.');
    if (parts.length !== 3) {
      return false;
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      return false;
    }
    return JSON.parse(decoded);
  }

  urlBase64Decode(str: string) {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
        case 0:
            break;
        case 2:
            output += '==';
            break;
        case 3:
            output += '=';
            break;
        default:
            // tslint:disable-next-line:no-string-throw
            throw 'Illegal base64url string!';
    }
    return decodeURIComponent((<any>window).escape(window.atob(output)));
  }

}
