import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html' ,
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
  
 public nodes = [
    {

      name: '2010 Session',
      value:'',
      children: [
        { name: '	DNA Database Expansion', value: 'http://dnaresource.com/documents/2010ExpansionBillTrackingList.pdf' }
      ]
    },
    {

      name: '2009 Session',
      value:'',
      children: [
        { name: 'DNA Database Expansion', value: 'http://dnaresource.com/documents/2009ExpansionBillTrackingList.pdf' }
      ]
    },
    {

      name: '2008 Session',
      value:'',
      children: [
        { name: 'DNA Database Expansion', value: 'http://dnaresource.com/documents/2008DNAExpansionLegislation.pdf' },
        { name: '	Post Conviction', value: 'http://dnaresource.com/documents/2008DNAPostConvictionLegislation.pdf' },
        { name: '	Statute of Limitations', value: 'http://dnaresource.com/documents/2008StatuteofLimitationsLegislation.pdf' }
      ]
    },
    {

      name: '2007 Session',
      value:'',
      children: [
        { name: 'DNA Database Expansion', value: 'http://dnaresource.com/documents/2007DNAExpansionLegislation.pdf' },
        { name: '	Post Conviction', value: 'http://dnaresource.com/documents/2007DNAPostConvictionLegislation.pdf' },
        { name: '	Statute of Limitations', value: 'http://dnaresource.com/documents/2007StatuteofLimitationsLegislation.pdf' }
      ]
    },
    {

      name: '2006 Session',
      value:'',
      children: [
        { name: 'DNA Database Expansion', value: 'http://dnaresource.com/documents/2006DNAExpansionLegislation.pdf' },
        { name: '	Post Conviction', value: 'http://dnaresource.com/documents/2006DNAPostConvictionLegislation.pdf' },
        { name: '	Statute of Limitations', value: 'http://dnaresource.com/documents/2006StatuteofLimitationsLegislation.pdf' }
      ]
    },
    {

      name: '2005 Session',
      value:'',
      children: [
        { name: 'DNA Database Expansion', value: 'http://dnaresource.com/documents/2005DNAExpansionLegislation.pdf' },
        { name: '	Post Conviction', value: 'http://dnaresource.com/documents/2005DNAPostConvictionLegislation.pdf' },
        { name: '	Statute of Limitations', value: 'http://dnaresource.com/documents/2005StatuteofLimitationsLegislation.pdf' }
      ]
    },
    {

      name: '2004 Session',
      value:'',
      children: [
        { name: 'DNA Database Expansion', value: 'http://dnaresource.com/documents/2004DNAExpansionLegislation.pdf' },
        { name: '	Post Conviction', value: 'http://dnaresource.com/documents/2004DNAPostConvictionLegislation.pdf' },
        { name: '	Statute of Limitations', value: 'http://dnaresource.com/documents/2004StatuteofLimitationsLegislation.pdf' }
      ]
    },
    {

      name: '2003 Session',
      value:'',
      children: [
        { name: 'DNA Database Expansion', value: 'http://dnaresource.com/documents/2003DNAExpansionLegislation.pdf' },
        { name: '	Post Conviction', value: 'http://dnaresource.com/documents/2003DNAPostConvictionLegislation.pdf' },
        { name: '	Statute of Limitations', value: 'http://dnaresource.com/documents/2003StatuteofLimitationsLegislation.pdf' }
      ]
    },
    {

      name: '2002 Session',
      value:'',
      children: [
        { name: 'DNA Database Expansion', value: 'http://dnaresource.com/documents/2002DNAExpansionLegislation.pdf' },
        { name: '	Post Conviction', value: 'http://dnaresource.com/documents/2002DNAPostConvictionLegislation.pdf' },
        { name: '	Statute of Limitations', value: 'http://dnaresource.com/documents/2002StatuteofLimitationsLegislation.pdf' }
      ]
    },
    {

      name: '2001 Session',
      value:'',
      children: [
        { name: 'DNA Database Expansion', value: 'http://dnaresource.com/documents/2001DNAExpansionLegislation.pdf' },
        { name: '	Post Conviction', value: 'http://dnaresource.com/documents/2001DNAPostConvictionLegislation.pdf' },
        { name: '	Statute of Limitations', value: 'http://dnaresource.com/documents/2001StatuteofLimitationsLegislation.pdf' }
      ]
    },
    {

      name: '2000 Session',
      value:'',
      children: [
        { name: 'DNA Database Expansion', value: 'http://dnaresource.com/documents/2000DNAExpansionLegislation.pdf' },
        { name: '	Post Conviction', value: 'http://dnaresource.com/documents/2000DNAPostConvictionLegislation.pdf' },
        { name: '	Statute of Limitations', value: 'http://dnaresource.com/documents/2000StatuteofLimitationsLegislation.pdf' }
      ]
    },
   
  ];
  constructor() { 
  }

    ngOnInit() {
       // this.items =  
    }

    
}
