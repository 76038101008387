import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MapService } from 'src/app/services/map.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import {ConfirmModel} from '../../model/confirm.model';
@Component({
  selector: 'app-add-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {
  ConfirmModel=new ConfirmModel();
  displayedColumns: string[] = ['name', 'agency',  'email','region','links','message','action'];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  
  addRegionForm: FormGroup;
  submission_id= 0;
  submissionArray = [
    // {display_text:"",link:"",submission_id:""}
  ]
  constructor(private fb: FormBuilder,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _location: Location,
    private _MapService: MapService,
    private adminService: AdminService,
    private _toastr: ToastrService,
    private auth: AuthService,
    private router: Router, ) {
    let token = this.storage.get('access_token');
    if (token == undefined || this.auth.decodeToken(token)['identity'][1] == 0) {
      this.router.navigateByUrl('/home');
    } else {
      this.getSubmissionData();
    }
  }

  getSubmissionData() { 
    this.adminService.GenericGetDataMethod("","submission").subscribe((res) => {
      debugger;
      if (res['data']!=undefined) {
        this.submissionArray = res['data'];
        this.dataSource = new MatTableDataSource(this.submissionArray);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      console.log(res);
    },
      (error) => {
        // this.auth.logout();
      });
  }
  ngOnInit() {
    this.addRegionForm = this.fb.group({
      'name': ['', Validators.required],
      'agency': [''],
      'email': [''],
      'region': [''],
      'links': [''],
      'message': [''],
    });
  }

  backClicked() {
    this._location.back();
  }
  addNewMedia(formData: NgForm) {
   if(this.submission_id==0) {
     this.InsertMedia(formData);
   } else {
     this.UpdateMedia(formData);
   }
  }

  InsertMedia(formData: NgForm) {
    debugger;
    this.adminService.GenericAddDataMethod(formData,"add_submission").subscribe((res) => {
      this._toastr.success(res.message, "Success");
      this.getSubmissionData();
      this.emptyData();
    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });
  }

  UpdateMedia(formData: NgForm) {
    debugger;
    formData['submission_id'] = this.submission_id;
    this.adminService.GenericAddDataMethod(formData,"edit_submission").subscribe((res) => {
      this._toastr.success(res.message, "Success");
      this.getSubmissionData();
     // this.emptyData(); 
    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });    
  }

  emptyData () {
    this.submission_id = 0;
    this.addRegionForm.controls['name'].setValue("");
      this.addRegionForm.controls['agency'].setValue("");
      this.addRegionForm.controls['email'].setValue("");
      this.addRegionForm.controls['region'].setValue("");
      this.addRegionForm.controls['links'].setValue("");
      this.addRegionForm.controls['message'].setValue("");
  }

  EditData(data) {
    this.submission_id = data.submission_id;
    this.addRegionForm.controls['name'].setValue(data.name);
      this.addRegionForm.controls['agency'].setValue(data.agency);
      this.addRegionForm.controls['email'].setValue(data.email);
      this.addRegionForm.controls['region'].setValue(data.region);
      this.addRegionForm.controls['links'].setValue(data.links);
      this.addRegionForm.controls['message'].setValue(data.message);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  RemoveData(data) {
    var _data = {
      "submission_id": data.submission_id
    }
    debugger;
    this.adminService.DeleteData(_data,'remove_submission').subscribe((res) => {
      this._toastr.success(res.msg,"Success");
      this.getSubmissionData();
     // this.emptyData();
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }
}
