import { Component } from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dnaresources',
  templateUrl: './dnaresource.component.html',
  styleUrls: ['./dnaresource.component.scss']
})
export class DnaresourcesComponent {
  public nodes = [
    {

      name: '	2008-2012',
      value:'',
      children: [
        { name: 'January 2008', value: '' },
        { name: 'February 2008', value: '' },
        { name: 'March 2008', value: '' },
        { name: 'May 2008', value: '' },
        { name: 'July & August 2008', value: '' },
        { name: 'September 2008', value: '' },
        { name: 'June 2009', value: '' },
        { name: 'August 2009', value: '' },
        { name: 'October 2009', value: '' },
        { name: 'December 2009', value: '' },
        { name: 'July 2010', value: '' },
        { name: 'October 2011', value: '' },
        { name: 'January 2012', value: '' },
        { name: 'July 2012', value: '' }
      ]
    },
    {

      name: '2007 DNA Reports',
      value:'',
      children: [
        { name: 'January 2007', value: '' },
        { name: 'March 2007', value: '' },
        { name: 'April 2007', value: '' },
        { name: 'May 2007', value: '' },
        { name: 'June 2007', value: '' },
        { name: 'July 2007', value: '' },
        { name: 'August 2007', value: '' },
        { name: 'September 2007', value: '' }
      ]
    },
    {
      name: '	2006 DNA Reports',
      value:'',
      children: [
       { name: 'January 2006', value: '' },
       { name: 'February 2006', value: '' },
       { name: 'March 2006', value: '' },
       { name: 'April 2006', value: '' },
       { name: 'May 2006', value: '' },
       { name: 'June 2006', value: '' },
       { name: 'July 2006', value: '' },
       { name: 'August 2006', value: '' },
       { name: 'September 2006', value: '' },
       { name: 'October 2006', value: '' },
       { name: 'November 2006', value: '' },
       { name: 'December 2006', value: '' }
      ]
    },
    {

      name: '	2005 DNA Reports',
      value:'',
      children: [
       
        { name: 'January 14, 2005', value: '' },
        { name: 'January 21, 2005', value: '' },
        { name: 'January 28, 2005', value: '' },
        { name: 'February 25, 2005', value: '' },
        { name: 'March 4, 2005', value: '' },
        { name: 'March 18, 2005', value: '' },
        { name: 'March 25, 2005', value: '' },
        { name: 'April 1, 2005', value: '' },
        { name: 'May 20, 2005', value: '' },
        { name: 'May 27, 2005', value: '' },
        { name: 'June 3, 2005', value: '' },
        { name: 'June 10, 2005', value: '' },
        { name: 'June 17, 2005', value: '' },
        { name: 'June 24, 2005', value: '' },
        { name: 'July 1, 2005', value: '' },
        { name: 'July 15, 2005', value: '' },
        { name: 'July 22, 2005', value: '' },
        { name: 'July 27, 2005', value: '' },
        { name: 'July 29, 2005', value: '' },
        { name: 'August 12, 2005', value: '' },
        { name: 'August 26, 2005', value: '' },
        { name: 'September 22, 2005', value: '' },
        { name: 'September 28, 2005', value: '' },
        { name: 'October 7, 2005', value: '' },
        { name: 'October 14, 2005', value: '' },
        { name: 'December 10, 2005', value: '' }
      ]
    },
    {

      name: '	2004 DNA Reports',
      value:'',
      children: [
        
        { name: 'January 2, 2004', value: '' },
        { name: 'January 9, 2004', value: '' },
        { name: 'January 16, 2004', value: '' },
        { name: 'January 23, 2004', value: '' },
        { name: 'January 30, 2004', value: '' },
        { name: 'February 6, 2004', value: '' },
        { name: 'February 13, 2004', value: '' },
        { name: 'February 27, 2004', value: '' },
        { name: 'March 5, 2004', value: '' },
        { name: 'March 12, 2004', value: '' },
        { name: 'March 19, 2004', value: '' },
        { name: 'March 26, 2004', value: '' },
        { name: 'April 2, 2004', value: '' },
        { name: 'April 9, 2004', value: '' },
        { name: 'April 16, 2004', value: '' },
        { name: 'April 23, 2004', value: '' },
        { name: 'April 30, 2004', value: '' },
        { name: 'May 7, 2004', value: '' },
        { name: 'May 14, 2004', value: '' },
        { name: 'May 21, 2004', value: '' },
        { name: 'May 28, 2004', value: '' },
        { name: 'June 4, 2004', value: '' },
        { name: 'June 11, 2004', value: '' },
        { name: 'June 18, 2004', value: '' },
        { name: 'July 2, 2004', value: '' },
        { name: 'July 9, 2004', value: '' },
        { name: 'July 16, 2004', value: '' },
        { name: 'July 23, 2004', value: '' },
        { name: 'July 30, 2004', value: '' },
        { name: 'August 13, 2004', value: '' },
        { name: 'August 20, 2004', value: '' },
        { name: 'August 27, 2004', value: '' },
        { name: 'September 3, 2004', value: '' },
        { name: 'September 10, 2004', value: '' },
        { name: 'September 17, 2004', value: '' },
        { name: 'September 24, 2004', value: '' },
        { name: 'October 8, 2004', value: '' },
        { name: 'October 15, 2004', value: '' },
        { name: 'October 22, 2004', value: '' },
        { name: 'October 29, 2004', value: '' },
        { name: 'November 5, 2004', value: '' },
        { name: 'November 19, 2004', value: '' },
        { name: 'December 3, 2004', value: '' },
        { name: 'December 10, 2004', value: '' }
      ]
    },
    {

      name: '	2003 DNA Reports',
      value:'',
      children: [
        
        { name: 'January 3, 2003', value: '' },
        { name: 'January 10, 2003', value: '' },
        { name: 'January 17, 2003', value: '' },
        { name: 'January 24, 2003', value: '' },
        { name: 'January 31, 2003', value: '' },
        { name: 'February 7, 2003', value: '' },
        { name: 'February 14, 2003', value: '' },
        { name: 'February 21, 2003', value: '' },
        { name: 'March 7, 2003', value: '' },
        { name: 'March 14, 2003', value: '' },
        { name: 'March 21, 2003', value: '' },
        { name: 'March 28, 2003', value: '' },
        { name: 'April 18, 2003', value: '' },
        { name: 'April 25, 2003', value: '' },
        { name: 'May 2, 2003', value: '' },
        { name: 'May 16, 2003', value: '' },
        { name: 'May 23, 2003', value: '' },
        { name: 'May 30, 2003', value: '' },
        { name: 'June 6, 2003', value: '' },
        { name: 'June 13, 2003', value: '' },
        { name: 'July 4, 2003', value: '' },
        { name: 'August 1, 2003', value: '' },
        { name: 'August 8, 2003', value: '' },
        { name: 'August 15, 2003', value: '' },
        { name: 'August 22, 2003', value: '' },
        { name: 'August 29, 2003', value: '' },
        { name: 'September 5, 2003', value: '' },
        { name: 'September 12, 2003', value: '' },
        { name: 'September 19, 2003', value: '' },
        { name: 'September 26, 2003', value: '' },
        { name: 'October 3, 2003', value: '' },
        { name: 'October 10, 2003', value: '' },
        { name: 'October 17, 2003', value: '' },
        { name: 'October 24, 2003', value: '' },
        { name: 'October 31, 2003', value: '' },
        { name: 'November 14, 2003', value: '' },
        { name: 'December 12, 2003', value: '' },
        { name: 'December 19, 2003', value: '' }
      ]
    },
    {

      name: '	2002 DNA Reports',
      value:'',
      children: [
        
        { name: 'January 4, 2002', value: '' },
        { name: 'January 11, 2002', value: '' },
        { name: 'January 18, 2002', value: '' },
        { name: 'January 25, 2002', value: '' },
        { name: 'February 1, 2002', value: '' },
        { name: 'February 8, 2002', value: '' },
        { name: 'February 15, 2002', value: '' },
        { name: 'February 22, 2002', value: '' },
        { name: 'March 8, 2002', value: '' },
        { name: 'March 15, 2002', value: '' },
        { name: 'March 22, 2002', value: '' },
        { name: 'March 28, 2002', value: '' },
        { name: 'April 5, 2002', value: '' },
        { name: 'April 12, 2002', value: '' },
        { name: 'April 19, 2002', value: '' },
        { name: 'April 26, 2002', value: '' },
        { name: 'May 3, 2002', value: '' },
        { name: 'May 10. 2002', value: '' },
        { name: 'May 24, 2002', value: '' },
        { name: 'May 31, 2002', value: '' },
        { name: 'June 7, 2002', value: '' },
        { name: 'June 14, 2002', value: '' },
        { name: 'June 21, 2002', value: '' },
        { name: 'June 28, 2002', value: '' },
        { name: 'July 12, 2002', value: '' },
        { name: 'July 19, 2002', value: '' },
        { name: 'August 2, 2002', value: '' },
        { name: 'August 16, 2002', value: '' },
        { name: 'August 23, 2002', value: '' },
        { name: 'August 30, 2002', value: '' },
        { name: 'September 6, 2002', value: '' },
        { name: 'September 13, 2002', value: '' },
        { name: 'September 20, 2002', value: '' },
        { name: 'September 23, 2002', value: '' },
        { name: 'September 27, 2002', value: '' },
        { name: 'October 4, 2002', value: '' },
        { name: 'October 11, 2002', value: '' },
        { name: 'October 18, 2002', value: '' },
        { name: 'October 25, 2002', value: '' },
        { name: 'November 1, 2002', value: '' },
        { name: 'November 8, 2002', value: '' },
        { name: 'November 15, 2002', value: '' },
        { name: 'November 22, 2002', value: '' },
        { name: 'November 29, 2002', value: '' },
        { name: 'December 6, 2002', value: '' },
        { name: 'December 13, 2002', value: '' },
        { name: 'December 20, 2002', value: '' },
        { name: 'December 27, 2002', value: '' }
      ]
    },
    {

      name: '	2001 DNA Reports',
      value:'',
      children: [
        
        { name: 'April 6, 2001', value: '' },
        { name: 'April 13, 2001', value: '' },
        { name: 'April 20, 2001', value: '' },
        { name: 'April 27, 2001', value: '' },
        { name: 'May 4, 2001', value: '' },
        { name: 'May 18, 2001', value: '' },
        { name: 'May 22, 2001', value: '' },
        { name: 'May 25, 2001', value: '' },
        { name: 'June 1, 2001', value: '' },
        { name: 'June 8, 2001', value: '' },
        { name: 'June 15, 2001', value: '' },
        { name: 'June 22, 2001', value: '' },
        { name: 'June 29, 2001', value: '' },
        { name: 'July 13, 2001', value: '' },
        { name: 'July 27, 2001', value: '' },
        { name: 'August 3, 2001', value: '' },
        { name: 'August 10, 2001', value: '' },
        { name: 'August 17, 2001', value: '' },
        { name: 'August 31, 2001', value: '' },
        { name: 'September 14, 2001', value: '' },
        { name: 'September 21, 2001', value: '' },
        { name: 'September 28, 2001', value: '' },
        { name: 'October 5, 2001', value: '' },
        { name: 'October 12, 2001', value: '' },
        { name: 'October 19, 2001', value: '' },
        { name: 'October 26, 2001', value: '' },
        { name: 'November 2, 2001', value: '' },
        { name: 'November 9, 2001', value: '' },
        { name: 'November 23, 2001', value: '' },
        { name: 'November 30, 2001', value: '' },
        { name: 'December 5, 2001', value: '' },
        { name: 'December 14, 2001', value: '' },
        { name: 'December 21, 2001', value: '' }
      ]
    },
    {

      name: '	2000 DNA Reports',
      value:'',
      children: [
        

        { name: 'January 22, 2000', value: '' },
        { name: 'January 27, 2000', value: '' },
        { name: 'February 4, 2000', value: '' },
        { name: 'February 11, 2000', value: '' },
        { name: 'February 18, 2000', value: '' },
        { name: 'February 25, 2000', value: '' },
        { name: 'March 3, 2000', value: '' },
        { name: 'March 10, 2000', value: '' },
        { name: 'March 17, 2000', value: '' },
        { name: 'March 24, 2000', value: '' },
        { name: 'March 31, 2000', value: '' },
        { name: 'April 7, 2000', value: '' },
        { name: 'April 14, 2000', value: '' },
        { name: 'April 21, 2000', value: '' },
        { name: 'April 28, 2000', value: '' },
        { name: 'May 5, 2000', value: '' },
        { name: 'May 12, 2000', value: '' },
        { name: 'May 19, 2000', value: '' },
        { name: 'May 26, 2000', value: '' },
        { name: 'June 2, 2000', value: '' },
        { name: 'June 9, 2000', value: '' },
        { name: 'June 16, 2000', value: '' },
        { name: 'June 22, 2000', value: '' },
        { name: 'June 30, 2000', value: '' },
        { name: 'July 7, 2000', value: '' },
        { name: 'July 21, 2000', value: '' },
        { name: 'July 28, 2000', value: '' },
        { name: 'August 4, 2000', value: '' },
        { name: 'August 11, 2000', value: '' },
        { name: 'August 25, 2000', value: '' },
        { name: 'September 1, 2000', value: '' },
        { name: 'September 8, 2000', value: '' },
        { name: 'September 15, 2000', value: '' },
        { name: 'September 22, 2000', value: '' },
        { name: 'September 29, 2000', value: '' },
        { name: 'October 06, 2000', value: '' },
        { name: 'October 13, 2000', value: '' },
        { name: 'October 20, 2000', value: '' },
        { name: 'October 27, 2000', value: '' },
        { name: 'November 3, 2000', value: '' },
        { name: 'November 10, 2000', value: '' },
        { name: 'Novermber 17, 2000', value: '' },
        { name: 'December 1, 2000', value: '' },
        { name: 'December 8, 2000', value: '' },
        { name: 'December 15, 2000', value: '' },
        { name: 'December 22, 2000', value: '' },
        { name: 'December 29, 2000', value: '' }
      ]
    }
  ]
  constructor(
  ){
    }
    
  }