import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm  } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import {AdminService} from '../../services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
@Component({
  selector: 'app-add-sub-region',
  templateUrl: './add-sub-region.component.html',
  styleUrls: ['./add-sub-region.component.scss']
})
export class AddSubRegionComponent implements OnInit {
//color= '#ffffff';
  addRegionForm: FormGroup;
  subRegion = [
    {subregion:"",sub_region_color:"#ffffff"}
  ]
  SubRegionData = localStorage.getItem("editSubRegion");
  constructor(private fb: FormBuilder,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _location: Location,
    private auth: AuthService,
    private adminService: AdminService,
    private _toastr: ToastrService,
    private router: Router,) {
      let token = this.storage.get('access_token');
      if(token == undefined || this.auth.decodeToken(token)['identity'][1] == 0){
        this.router.navigateByUrl('/home');
      }else {
        this.auth.users().subscribe((res) => {
          
        },
        (error) =>{
          this.auth.logout();
        });
      }
     
  }

  ngOnInit() {
    this.addRegionForm = this.fb.group({
      'region_id':localStorage.getItem('regionId'),
      'sub_region' : ['', Validators.required],
      'color' : ['#CCCCCC', Validators.required]
     
    });
    if(this.SubRegionData !="" && this.SubRegionData!=null)
     {
      var _regiondata = JSON.parse(this.SubRegionData);
      this.addRegionForm.controls['sub_region'].setValue(_regiondata.sub_region);
      this.addRegionForm.controls['color'].setValue(_regiondata.color);
     // this.addRegionForm.controls['sub_region_id'].setValue(_regiondata.sub_region_id);
     }
  }
// get sub_region(): any {return this.addRegionForm.get('sub_region')};
  addSubRegion(formData: NgForm){
    debugger;
    if(this.addRegionForm.valid){
      if(this.SubRegionData !="" && this.SubRegionData!=null) {
        this.updateSubRegion(formData);
      } else {
        this.insertSubRegion(formData);
      }
    } 

    
  }

  backClicked() {
    this._location.back();
}

insertSubRegion(formData: NgForm) {
 debugger;
  this.adminService.AddSubRegion(formData).subscribe((res) => {
    this._toastr.success(res.msg,"Success");
    this.addRegionForm.controls['sub_region'].setValue("");
    this.addRegionForm.controls['color'].setValue("#CCCCCC");
    }, 
    (error) =>{
      this._toastr.error(error.error.error,"Error");
      });
}

updateSubRegion(formData: NgForm) {
  var _regiondata = JSON.parse(this.SubRegionData);
  formData['sub_region_id']=_regiondata.sub_region_id;
  this.adminService.UpdateSubRegion(formData).subscribe((res) => {
    this._toastr.success(res.msg,"Success");
    // this.addRegionForm.controls['sub_region'].setValue("");
    // this.addRegionForm.controls['color'].setValue("#CCCCCC");
    }, 
    (error) =>{
      this._toastr.error(error.error.error,"Error");
      });
}
  // addSubRegion() {
  //   this.subRegion.push({subregion:"",sub_region_color:"#CCCCCC"});
  // }
}
