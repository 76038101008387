import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthService } from '../../../auth/auth.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MapService } from 'src/app/services/map.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import {ConfirmModel} from '../../../model/confirm.model';
@Component({
  selector: 'app-add-requests-media',
  templateUrl: './requests-media.component.html',
  styleUrls: ['./requests-media.component.scss']
})
export class RequestsMediaComponent implements OnInit {
  ConfirmModel = new ConfirmModel();
  displayedColumns: string[] = ['first_name','last_name','email','reason','display_text', 'link','status','submission_datetime', 'Action'];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  status="0";
  addRegionForm: FormGroup;
  resourcesArray = [
    // {display_text:"",link:"",additional_resources_id:""}
  ]
  constructor(private fb: FormBuilder,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _location: Location,
    private _MapService: MapService,
    private auth: AuthService,
    private adminService: AdminService,
    private _toastr: ToastrService,
    private router: Router, ) {
    let token = this.storage.get('access_token');
    if (token == undefined || this.auth.decodeToken(token)['identity'][1] == 0) {
      this.router.navigateByUrl('/home');
    } else {
      this.getMedia();
    }
  }

  ngOnInit() {
    this.addRegionForm = this.fb.group({
      'display_text': [''],
      'link': [''],
      'status': this.status,
      'first_name':'',
      'last_name':'',
      'reason':'',
      'email':'',
      'ms_id':0
    });
  }

  getMedia() {
    //var id = localStorage.getItem("statisticID");
    var params = {
      'region_id' : localStorage.getItem("regionId"),
      'sub_region_id': localStorage.getItem("subRegionId")
    };
    debugger; 
    this.adminService.GenericAddDataMethod(params,"show_request_media").subscribe((res) => {
      debugger;
      if (res['data']!=undefined) {
        this.resourcesArray = res['data'];

        this.dataSource = new MatTableDataSource(this.resourcesArray);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      console.log(res);
    },
      (error) => {
        //  this.auth.logout();
      });
  }
  

  backClicked() {
    this._location.back();
  }

  addNewResources(formData: NgForm) {
    this.UpdateResources(formData);
  }


  UpdateResources(formData: NgForm) {
    debugger;
    this.adminService.GenericAddDataMethod(formData,"edit_request_media").subscribe((res) => {
      this._toastr.success(res.message, "Success");
      this.getMedia();
    //  this.emptyData();
    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });
  }

  emptyData() {
    this.addRegionForm.controls['display_text'].setValue("");
    this.addRegionForm.controls['link'].setValue("");
    this.addRegionForm.controls['first_name'].setValue("");
    this.addRegionForm.controls['last_name'].setValue("");
    this.addRegionForm.controls['reason'].setValue("");
    this.addRegionForm.controls['email'].setValue("");
    this.addRegionForm.controls['status'].setValue(status);
    this.addRegionForm.controls['ms_id'].setValue(0)
  }

  EditData(data) {
    debugger;
    this.addRegionForm.controls['display_text'].setValue(data.display_text);
    this.addRegionForm.controls['link'].setValue(data.link);
    this.addRegionForm.controls['first_name'].setValue(data.first_name);
    this.addRegionForm.controls['last_name'].setValue(data.last_name);
    this.addRegionForm.controls['reason'].setValue(data.reason);
   // this.addRegionForm.controls['submission_datetime'].setValue(data.submission_datetime);
    this.addRegionForm.controls['email'].setValue(data.email);
    this.addRegionForm.controls['status'].setValue(data.status);
   //this.status = data.status
    this.addRegionForm.controls['ms_id'].setValue(data.ms_id); 
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  RemoveData(data) {
    var _data = {
      "ms_id": data.ms_id
    }
    debugger;
    this.adminService.DeleteData(_data,'remove_request_media').subscribe((res) => {
      this._toastr.success(res.msg,"Success");
      this.getMedia();
     // this.emptyData();
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }

  Approve(data) {
    var _data = {
      "ms_id": data.ms_id,
      "media_id":data.media_id
    } 
    debugger;
    this.adminService.GenericAddDataMethod(_data,'approve_request_media').subscribe((res) => {
    
      this._toastr.success(res.message,"Success");
      this.getMedia();
     // this.emptyData();
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }
}
