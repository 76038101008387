import { Component, OnInit, Inject, ElementRef, ViewChild, KeyValueDiffers } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MapService } from '../services/map.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Location } from '@angular/common';
// import panzoom from "panzoom";
import * as $ from 'jquery';
import { AdminService } from '../services/admin.service';
import { ToastrService } from 'ngx-toastr';
declare var google: any;
declare var $: $;
declare var panzoom: any;
declare var countryArray: any;
@Component({
    selector: 'app-region',
    templateUrl: './region-data.component.html',
    styleUrls: ['./region-data.component.scss']
})
export class RegionDataComponent implements OnInit {
    typeId = 0;
    ARArray = [];
    MArray = [];
    RAArray = [];
    DArray = [];
    isEdit = false;
    isEdit2 = false;
    isEdit3 = false;
    isEdit4 = false;
    isEdit5 = false;
    reasonModal = {
        first_name: "",
        last_name: "",
        email: "",
        reason: "",
    };
    changedObject:any = {};
    public responseData: any = {};
    public barChartOptions = {
        scaleShowVerticalLines: false,
        responsive: true
    };
    public barChartLabels = [];
    public barChartType = 'bar';
    public barChartLegend = true;
    public barChartData = [];

    constructor(private _MapService: MapService, private router: Router,
        private deviceService: DeviceDetectorService,
        @Inject(LOCAL_STORAGE) private storage: StorageService, differs: KeyValueDiffers,
        private _location: Location,
        private auth: AuthService, private adminService: AdminService, private _toastr: ToastrService, ) {
        let token = this.storage.get('access_token');

        if (token == undefined || token == null) {
            this.router.navigateByUrl('/home');
        } 
        // else {
        //     this.auth.users().subscribe((res) => {
          
        //     },
        //     (error) =>{
        //       this.auth.logout();
        //     });
        // }

    }
    ngOnInit() {
        this._MapService.getData().subscribe((response) => {
            debugger;
            console.log(response);
            this.responseData = response['data'][0];
            if (this.responseData.data.length > 0) {
                var crime_scene = { data: [], label: 'Crime Scene' }
                var suspect_offender = { data: [], label: 'Suspect Offender' }
                this.responseData.data.forEach(element => {
                    this.barChartLabels.push(element.year);
                    crime_scene.data.push(element.crime_scene);
                    suspect_offender.data.push(element.suspect_offender);
                });
                this.barChartData.push(crime_scene);
                this.barChartData.push(suspect_offender);
                console.log(this.barChartData);
            }
        },
            (error) => {

            });
    }

    backClicked() {
        this._location.back();
    }

    EditClicked2() {
        this.isEdit2 = true;
    }

    RemoveClicked2() {
        this.isEdit2 = false;
    }

    EditClicked3() {
        this.isEdit3 = true;
    }

    RemoveClicked3() {
        this.isEdit3 = false;
    }

    EditClicked4() {
        this.isEdit4 = true;
    }

    RemoveClicked4() {
        this.isEdit4 = false;
    }

    EditClicked5() {
        this.isEdit5 = true;
    }

    RemoveClicked5() {
        this.isEdit5 = false;
    }

    EditClicked() {
        this.isEdit = true;
    }

    RemoveClicked() {
        this.isEdit = false;
    }

    RAChanges(idx) {
        if (this.RAArray.indexOf(idx) == -1) {
            this.RAArray.push(idx);
        }
    }

    DChanges(idx) {
        if (this.DArray.indexOf(idx) == -1) {
            this.DArray.push(idx);
        }
    }

    ARChanges(idx) {
        if (this.ARArray.indexOf(idx) == -1) {
            this.ARArray.push(idx);
        }
    }

    MChanges(idx) {
        if (this.MArray.indexOf(idx) == -1) {
            this.MArray.push(idx);
        }
        console.log(this.MArray);
    }
    saveRequest() {
        debugger;
        if (this.typeId == 1) {
            this.request_For_AdditionalResources();
        } else if (this.typeId == 2) {
            this.request_For_Media();
        } else if (this.typeId == 3) {
            this.request_For_ResponsibleAgencies();
        } else if (this.typeId == 4) {
            this.request_For_Data();
        } else if(this.typeId==5) {
            this.request_For_Statistics();
        }
    }
    request_For_AdditionalResources() {
        // var data =
        var ad = [];
        this.ARArray.forEach(element => {
            ad.push(this.responseData.additional_resources[element]);
        });
        this.reasonModal["additional_resources"] = ad;
        debugger;
        this.adminService.GenericAddDataMethod(this.reasonModal, "request_additional_resource").subscribe((res) => {
            this._toastr.success(res.msg, "Success");
             this.reset();
        },
            (error) => {
                this._toastr.error(error.error.error, "Error");
            });

    }

    request_For_Media() {
        var ad = [];
        this.MArray.forEach(element => {
            ad.push( this.responseData.media[element]);
        });
        this.reasonModal["media"] =ad;
        debugger;
        this.adminService.GenericAddDataMethod(this.reasonModal, "request_media").subscribe((res) => {
            this._toastr.success(res.msg, "Success");
            this.reset();
        },
            (error) => {
                this._toastr.error(error.error.error, "Error");
            });

    }

    request_For_ResponsibleAgencies() {
        // var data =

        var ad = [];
        this.RAArray.forEach(element => {
            ad.push( this.responseData.responsible_agencies[element]);
        });
        this.reasonModal["responsible_agencies"] = ad;
        debugger;
        this.adminService.GenericAddDataMethod(this.reasonModal, "request_responsible_agencies").subscribe((res) => {
            this._toastr.success(res.msg, "Success");
            this.reset();
        },
            (error) => {
                this._toastr.error(error.error.error, "Error");
            });
    }

    request_For_Data() {
        var ad = [];
        this.DArray.forEach(element => {
            ad.push(this.responseData.data[element]);
        });
        this.reasonModal["data"] = ad;
        debugger;
        this.adminService.GenericAddDataMethod(this.reasonModal, "request_data").subscribe((res) => {
            this._toastr.success(res.msg, "Success");
            this.reset();
        },
            (error) => {
                this._toastr.error(error.error.error, "Error");
            });
    }

    request_For_Statistics() {
        var statistics_data = [];
        this.responseData['as_switch'] = String(this.responseData['as_switch']);
        this.responseData['at_switch'] = String(this.responseData['at_switch']);
        this.responseData['cs_switch'] = String(this.responseData['cs_switch']);
        this.responseData['ct_switch'] = String(this.responseData['ct_switch']);
        this.responseData['ra_switch'] = String(this.responseData['ra_switch']);
        this.responseData['rc_switch'] = String(this.responseData['rc_switch']);
        statistics_data.push(this.responseData);
        //statistics_data[]
        this.reasonModal["statistics"] = statistics_data;
        debugger;
        this.adminService.GenericAddDataMethod(this.reasonModal, "request_statistics").subscribe((res) => {
            this._toastr.success(res.msg, "Success");
            this.reset();
        },
            (error) => {
                this._toastr.error(error.error.error, "Error");
            });
    }

    AdditionalResourcesChanges(idx) {

    }

    setType(id) {
        this.typeId = id;
    }

    reset() {
        this.isEdit = false;
        this.isEdit2 = false;
        this.isEdit3 = false;
        this.isEdit4 = false;
        this.isEdit5 = false;
        this.DArray = [];
        this.MArray=[];
        this.ARArray=[];
        this.RAArray=[];
        this.changedObject = {};
    }

    statisticsChanged(key) {
       // this.changedObject['statistics_id'] = this.responseData['statistics_id'];
        //this.changedObject[key] = this.responseData[key];
    }
}

