import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NgForm } from '@angular/forms';
// import {Md5} from "md5-typescript";
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class AuthService {

  baseUrl =  environment.URL;

  constructor(public http: HttpClient,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    ) { }

  
  headers = new HttpHeaders({ 
    'Content-Type' : 'application/json',
    'Authorization' : 'Bearer ' + this.storage.get('access_token') 
  });
  

  login(formData: NgForm) {
    var params = {
      'username' : formData['email'],
      'password' : formData['password']// Md5.init()
    };
    return this.http.post<any>( this.baseUrl + 'login', params);
  }

  users(){
    return this.http.get( this.baseUrl + 'users', {
      headers: this.headers
    });
  }

  addUser(formData: NgForm){
    var params = {
      'username' : formData['username'],
      'password' : formData['password'],
      'email' : formData['email'],
      'access_level' : formData['access_level'],
    };
    return this.http.post<any>( this.baseUrl + 'adduser', params, {
      headers: this.headers
    });
  }

  editUser(formData: NgForm, user_id){
    var params = {
      'username' : formData['username'],
      'email' : formData['email'],
      'access_level' : formData['access_level'],
      'user_id' : user_id
    };
    if(formData['password'] != '' && formData['password'] != null){
      params['password'] = formData['password'];
    }
      
    return this.http.post<any>( this.baseUrl + 'edituser', params, {
      headers: this.headers
    });
  }

  delUser(id){
    let headers = new HttpHeaders({ 
      'Content-Type' : 'application/json',
      'Authorization' : 'Bearer ' + this.storage.get('access_token'),
    });
    let options = {
      headers: headers,
      body: {
        'user_id' : id
      }
    }
    return this.http.delete(this.baseUrl+'deleteuser', options);
  }

  logout(){
    let headers = new HttpHeaders({ 
      'Content-Type' : 'application/json',
      'Authorization' : 'Bearer ' + this.storage.get('access_token'),
    });
    let options = {
      headers: headers
    }
    return this.http.delete(this.baseUrl+'logout', options).subscribe(data => {
      this.storage.remove('access_token');
      window.location.reload();
    },
    (error) => {
      this.storage.remove('access_token');
      window.location.reload();
    });
  }

  decodeToken(token: string = '') {
    
    const parts = token.split('.');
    if (parts.length !== 3) {
      return false;
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      return false;
    }
    return JSON.parse(decoded);
  }

  urlBase64Decode(str: string) {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
        case 0:
            break;
        case 2:
            output += '==';
            break;
        case 3:
            output += '=';
            break;
        default:
            // tslint:disable-next-line:no-string-throw
            throw 'Illegal base64url string!';
    }
    return decodeURIComponent((<any>window).escape(window.atob(output)));
  }

  forgot(formData: NgForm) {
    var params = {
      'email' : formData['email'],
    };
    return this.http.post<any>( this.baseUrl + 'reset', params);
  }  
}
