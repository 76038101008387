import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MapService } from 'src/app/services/map.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { HttpParams } from '@angular/common/http';
import {ConfirmModel} from '../../model/confirm.model';
@Component({
  selector: 'app-add-agencies',
  templateUrl: './agencies.component.html',
  styleUrls: ['./agencies.component.scss']
})
export class AgenciesComponent implements OnInit {
  ConfirmModel = new ConfirmModel();
  displayedColumns: string[] = ['description', 'agency', 'ministry', 'website', 'Action'];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  addRegionForm: FormGroup;
  ra_id = 0;
  agencies = [
    // { agency: "", description: "", ministry: "", website: "", ra_id: "" }
  ]
  constructor(private fb: FormBuilder,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _location: Location,
    private _MapService: MapService,
    private auth: AuthService,
    private adminService: AdminService,
    private _toastr: ToastrService,
    private router: Router, ) {
    let token = this.storage.get('access_token');
    if (token == undefined || this.auth.decodeToken(token)['identity'][1] == 0) {
      this.router.navigateByUrl('/home');
    } else {
     this.getAgencies();
    }
  }

  getAgencies() {

    var id = localStorage.getItem("statisticID");
    this._MapService.getAgencies(id).subscribe((res) => {
      debugger;
      if (res['data']!=undefined) {
        this.agencies = res['data'];
        console.log(this.agencies);
        this.dataSource = new MatTableDataSource(this.agencies);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      console.log(res);
    },
      (error) => {
      //  this.auth.logout();
      });
  }
  ngOnInit() {
    this.addRegionForm = this.fb.group({
      'statistics_id':localStorage.getItem('statisticID'),
      'description' : [''],
      'agency' : [''],
      'ministry' : [''],
      'website' : ['']
    });
  }

  addAgency() {
    this.agencies.push({ agency: "", description: "", ministry: "", website: "", ra_id: "" });
  }
  addNewAgencies(formData: NgForm) {
   if(this.ra_id==0) {
     this.InsertAgencies(formData);
   } else {
    this.UpdateAgencies(formData);
   }
    
  }

  InsertAgencies(formData: NgForm) {
    this.adminService.AddAgencies(formData).subscribe((res) => {
      this._toastr.success(res.msg,"Success");
      this.getAgencies();
      this.emptyData();
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }

  UpdateAgencies(formData: NgForm) {
    formData['ra_id'] = this.ra_id;
    this.adminService.UpdateAgencies(formData).subscribe((res) => {
      this._toastr.success(res.msg,"Success");
      this.getAgencies();
     // this.emptyData();
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }
  backClicked() {
    this._location.back();
  }

  EditData(data) {
    this.ra_id = data.ra_id;
    this.addRegionForm.controls['description'].setValue(data.description);
    this.addRegionForm.controls['agency'].setValue(data.agency);
    this.addRegionForm.controls['ministry'].setValue(data.ministry);
    this.addRegionForm.controls['website'].setValue(data.website);
  }

  emptyData(){
    this.ra_id = 0;
    this.addRegionForm.controls['description'].setValue("");
      this.addRegionForm.controls['agency'].setValue("");
      this.addRegionForm.controls['ministry'].setValue("");
      this.addRegionForm.controls['website'].setValue("");
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  RemoveData(data) {
    var _data = {
      "ra_id": data.ra_id
    }
   // debugger;
   let httpParams = new HttpParams().set("ra_id", data.ra_id);
    this.adminService.DeleteData(_data,'delete_responsible_agencies').subscribe((res) => {
      this._toastr.success(res.msg,"Success");
      this.getAgencies();
     // this.emptyData();
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }
}
