import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {
  blogsList = [];
  constructor(private adminService: AdminService, private router: Router, private _location: Location) {

  }
  ngOnInit() {
    this.getBlogs();
  }

  getBlogs() {
    this.adminService.GenericGetDataMethod("", "blog").subscribe((res) => {
      debugger;
      // this._toastr.success(res.msg,"Success");
      if (res != null) {
        this.blogsList = res.data;
      }
    },
      (error) => {
        //  this._toastr.error("Error Occur while getting featured text","Error");
      });
  }

  blogDetails(id) {
    localStorage.setItem("blogId", id);
    this.router.navigate(['/blog-details']);
  }

  backClicked() {
    this._location.back();
  }
}
