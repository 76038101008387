import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthService } from '../../../auth/auth.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MapService } from 'src/app/services/map.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import {ConfirmModel} from '../../../model/confirm.model';
@Component({
  selector: 'app-add-request-data',
  templateUrl: './request-data.component.html',
  styleUrls: ['./request-data.component.scss']
})
export class RequestDataComponent implements OnInit {
ConfirmModel = new ConfirmModel();
  displayedColumns: string[] = ['first_name','last_name','email','reason','year', 'crime_scene', 'suspect_offender','status','submission_datetime', 'Action'];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  
  addRegionForm: FormGroup;
  yearly_data = [
    // { crime_scene: "", suspect_offender: "", year: "", data_id: "" }
  ];
  years = [];
  data_id = 0;
  date: Date = new Date();
  year = this.date.getFullYear();
    settings = {
        bigBanner: false,
        timePicker: false,
        format: 'yyyy',
        defaultOpen: false
    }
  constructor(private fb: FormBuilder,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _location: Location,
    private _MapService: MapService,
    private auth: AuthService,
    private adminService: AdminService,
    private _toastr: ToastrService,
    private router: Router, ) {
      this.createYear();
    let token = this.storage.get('access_token');
    if (token == undefined || this.auth.decodeToken(token)['identity'][1] == 0) {
      this.router.navigateByUrl('/home');
    } else {
      this.getYearlyData();
    }
  }

  getYearlyData() {
    var params = {
      'region_id' : localStorage.getItem("regionId"),
      'sub_region_id': localStorage.getItem("subRegionId")
    };
    debugger; 
    this.adminService.GenericAddDataMethod(params,"show_request_data").subscribe((res) => {
      debugger;
      if (res['data']!=undefined) {
        this.yearly_data = res['data'];

        this.dataSource = new MatTableDataSource(this.yearly_data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      console.log(res);
    },
      (error) => {
        //  this.auth.logout();
      });
  }
  ngOnInit() {
    this.addRegionForm = this.fb.group({
      'crime_scene' : [''],
      'suspect_offender' : [''],
      'year' : [this.year],
      'status': "0",
      'first_name':'',
      'last_name':'',
      'reason':'',
      'email':'',
      'ds_id':0
    });
  }

  addYearlyData() {
    this.yearly_data.push({ crime_scene: "", suspect_offender: "", year: "", data_id: "" });
  }

  backClicked() {
    this._location.back();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  addNewData(formData: NgForm) {
    this.UpdateYearlyData(formData);
    
  }

  UpdateYearlyData(formData: NgForm) {
    debugger;
    this.adminService.GenericAddDataMethod(formData,"edit_request_data").subscribe((res) => {
      this._toastr.success(res.message, "Success");
      this.getYearlyData();
    //  this.emptyData();
    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });
  }

  emptyData() {
    this.addRegionForm.controls['crime_scene'].setValue("");
    this.addRegionForm.controls['suspect_offender'].setValue("");
    this.addRegionForm.controls['year'].setValue("");
    this.addRegionForm.controls['first_name'].setValue("");
    this.addRegionForm.controls['last_name'].setValue("");
    this.addRegionForm.controls['reason'].setValue("");
    this.addRegionForm.controls['email'].setValue("");
    this.addRegionForm.controls['status'].setValue("");
    this.addRegionForm.controls['ds_id'].setValue(0)
  }

  EditData(data) {
    this.addRegionForm.controls['crime_scene'].setValue(data.crime_scene);
    this.addRegionForm.controls['suspect_offender'].setValue(data.suspect_offender);
    this.addRegionForm.controls['year'].setValue(data.year);
    this.addRegionForm.controls['first_name'].setValue(data.first_name);
    this.addRegionForm.controls['last_name'].setValue(data.last_name);
    this.addRegionForm.controls['reason'].setValue(data.reason);
   // this.addRegionForm.controls['submission_datetime'].setValue(data.submission_datetime);
    this.addRegionForm.controls['email'].setValue(data.email);
    this.addRegionForm.controls['status'].setValue(data.status);
   //this.status = data.status
    this.addRegionForm.controls['ds_id'].setValue(data.ds_id); 
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  RemoveData(data) {
    var _data = {
      "ds_id": data.ds_id
    }
    debugger;
    this.adminService.DeleteData(_data,'remove_request_data').subscribe((res) => {
      this._toastr.success(res.msg,"Success");
      this.getYearlyData();
     // this.emptyData();
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }

  Approve(data) {
    var _data = {
      "ds_id": data.ds_id,
      "data_id":data.data_id
    } 
    debugger;
    this.adminService.GenericAddDataMethod(_data,'approve_request_data').subscribe((res) => {
    
      this._toastr.success(res.message,"Success");
      this.getYearlyData();
     // this.emptyData();
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }


  createYear() {
    for(var i = 1900; i<=2099;i++) {
      this.years.push(i);
    }
  }

  
}
