import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MapService } from 'src/app/services/map.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ConfirmModel } from '../../model/confirm.model';
@Component({
  selector: 'app-admin-featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.scss']
})
export class FeaturedComponent implements OnInit {
  addRegionForm: FormGroup;
  ConfirmModel = new ConfirmModel();
  constructor(private fb: FormBuilder,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private auth: AuthService,
    private adminService: AdminService,
    private _toastr: ToastrService,
    private router: Router, ) {
    let token = this.storage.get('access_token');
    if (token == undefined || this.auth.decodeToken(token)['identity'][1] == 0) {
      this.router.navigateByUrl('/home');
    }
  }

  ngOnInit() {

    this.addRegionForm = this.fb.group({
      'display_text': ['', Validators.required],
      'link': ['', Validators.required]

    });
    this.getFeatured();
  }
  addRegion(formData: NgForm) {
    debugger;
    if (this.addRegionForm.valid) {
      this.InsertFeatured(formData);
    } else {
      this._toastr.error("All Fields are required", "Error");
    }
  }

  getFeatured() {
    this.adminService.GenericGetDataMethod("", "/featured").subscribe((res) => {
      debugger;
      // this._toastr.success(res.msg,"Success");
      if (res != null) {
        this.addRegionForm.controls['display_text'].setValue(res.data[0].display_text);
        this.addRegionForm.controls['link'].setValue(res.data[0].link);
      }
    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });
  }

  InsertFeatured(formData: NgForm) {
    this.adminService.GenericAddDataMethod(formData, "/update_featured").subscribe((res) => {
      this._toastr.success(res.msg, "Success");
      window.location.reload();
    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });
  }

  removeData() {
    this.adminService.DeleteData(null, "/remove_featured").subscribe((res) => {
      this._toastr.success(res.msg, "Success");
      this.getFeatured();
      window.location.reload();
    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });
  }

}
