import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MapService } from 'src/app/services/map.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import {ConfirmModel} from '../../model/confirm.model';
@Component({
  selector: 'app-add-admin-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class AdminResourcesComponent implements OnInit {
  ConfirmModel = new ConfirmModel();
  displayedColumns: string[] = ['display_text', 'link',  'Action'];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  
  addRegionForm: FormGroup;
  additional_resources_id = 0;
  resourcesArray = [
    // {display_text:"",link:"",additional_resources_id:""}
  ]
  constructor(private fb: FormBuilder,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _location: Location,
    private _MapService: MapService,
    private auth: AuthService,
    private adminService: AdminService,
    private _toastr: ToastrService,
    private router: Router, ) {
    let token = this.storage.get('access_token');
    if (token == undefined || this.auth.decodeToken(token)['identity'][1] == 0) {
      this.router.navigateByUrl('/home');
    } else {
      this.getResources();
    }
  }

  ngOnInit() {
    this.addRegionForm = this.fb.group({
      'statistics_id': localStorage.getItem('statisticID'),
      'display_text': [''],
      'link': ['']
    });
  }

  getResources() {
    var id = localStorage.getItem("statisticID");
    this._MapService.getResources(id).subscribe((res) => {
      debugger;
      if (res['data']!=undefined) {
        this.resourcesArray = res['data'];

        this.dataSource = new MatTableDataSource(this.resourcesArray);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      console.log(res);
    },
      (error) => {
        //  this.auth.logout();
      });
  }
  addResources() {
    this.resourcesArray.push({ display_text: "", link: "", additional_resources_id: "" });
  }

  backClicked() {
    this._location.back();
  }

  addNewResources(formData: NgForm) {
    if(this.additional_resources_id == 0) {
      this.InsertResources(formData);
    } else {
      this.UpdateResources(formData);
    }
  }

  InsertResources(formData: NgForm) {
    debugger;
    this.adminService.AddResourcesData(formData).subscribe((res) => {
      this._toastr.success(res.msg, "Success");
      this.getResources();
      this.emptyData();
    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });
  }

  UpdateResources(formData: NgForm) {
    debugger;
    formData['additional_resources_id'] = this.additional_resources_id;
    this.adminService.UpdateResourcesData(formData).subscribe((res) => {
      this._toastr.success(res.msg, "Success");
      this.getResources();
    //  this.emptyData();
    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });
  }

  emptyData() {
    this.additional_resources_id = 0;
    this.addRegionForm.controls['display_text'].setValue("");
    this.addRegionForm.controls['link'].setValue("");
  }

  EditData(data) {
    debugger;
    this.additional_resources_id = data.additional_resources_id;
    this.addRegionForm.controls['display_text'].setValue(data.display_text);
    this.addRegionForm.controls['link'].setValue(data.link);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  RemoveData(data) {
    var _data = {
      "additional_resources_id": data.additional_resources_id
    }
    debugger;
    this.adminService.DeleteData(_data,'delete_additional_resources').subscribe((res) => {
      this._toastr.success(res.msg,"Success");
      this.getResources();
     // this.emptyData();
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }
}
