import { Component, OnInit } from '@angular/core';
import { LoginComponent } from '../login/login.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  forgotForm: FormGroup;
  isError = 0;
  isErrMsg = '';
  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.forgotForm = this.fb.group({
      'email' : ['', [Validators.required, Validators.email]],
    });
  }

  dismiss(){
    this.modalService.dismissAll();
  }

  forgotPassword(formData: NgForm){
    this.auth.forgot(formData).subscribe((res) => {
      console.log(res);
      this.isErrMsg = res['message'];
    },
    (error) => {
    });
  }

  loginModal(){
    this.modalService.dismissAll();
    this.modalService.open(LoginComponent);
  }


}
