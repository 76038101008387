import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hit-of-the-year',
  templateUrl: './hit-of-the-year-2020.component.html',
  styleUrls: ['../hit-of-the-year.component.scss']
})
export class HitOfTheYear2020Component implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
