import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  displayedColumns: string[] = ['userid', 'username', 'email', 'access_level', 'last_login', 'action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}

export interface PeriodicElement {
  userid: number;
  username: string;
  email: string;
  access_level: number;
  last_login: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {userid: 1, username: 'Hydrogen', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 2, username: 'Helium', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 3, username: 'Lithium', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 4, username: 'Beryllium', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 5, username: 'Boron', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 6, username: 'Carbon', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 7, username: 'Nitrogen', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 8, username: 'Oxygen', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 9, username: 'Fluorine', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 10, username: 'Neon', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 11, username: 'Sodium', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 12, username: 'Magnesium', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 13, username: 'Aluminum', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 14, username: 'Silicon', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 15, username: 'Phosphorus', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 16, username: 'Sulfur', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 17, username: 'Chlorine', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 18, username: 'Argon', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 19, username: 'Potassium', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
  {userid: 20, username: 'Calcium', email: 'a@dataorg.com', access_level: 1, last_login: '2020-01-01 00:00:00'},
];