import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthService } from '../../../auth/auth.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MapService } from 'src/app/services/map.service';
import { Location } from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModel } from '../../../model/confirm.model';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class RequestStatisticsComponent implements OnInit {
  statisticsData: any;
  ConfirmModel = new ConfirmModel();
  displayedColumns: string[] = ['first_name', 'last_name', 'email', 'reason', 'status', 'submission_datetime', 'Action'];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  status = "0";
  addRegionForm: FormGroup;
  resourcesArray = [
    // {display_text:"",link:"",additional_resources_id:""}
  ]
  RequestData= [];
  constructor(private fb: FormBuilder,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _location: Location,
    private _MapService: MapService,
    private auth: AuthService,
    private adminService: AdminService,
    private _toastr: ToastrService,
    private router: Router, ) {
    let token = this.storage.get('access_token');
    if (token == undefined || this.auth.decodeToken(token)['identity'][1] == 0) {
      this.router.navigateByUrl('/home');
    } else {
      this.getCount();
      this.getMedia();
    }
  }

  ngOnInit() {

  }

  getMedia() {
    //var id = localStorage.getItem("statisticID");
    var params = {
      'region_id': localStorage.getItem("regionId"),
      'sub_region_id': localStorage.getItem("subRegionId")
    };
    debugger;
    this.adminService.GenericAddDataMethod(params, "show_request_statistics").subscribe((res) => {
      debugger;
      if (res['data'] != undefined) {
        this.resourcesArray = res['data'];

        this.dataSource = new MatTableDataSource(this.resourcesArray);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      console.log(res);
    },
      (error) => {
        //  this.auth.logout();
      });
  }


  backClicked() {
    this._location.back();
  }



  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  RemoveData(data) {
    var _data = {
      "ss_id": data.ss_id
    }
    debugger;
    this.adminService.DeleteData(_data, 'remove_request_statistics').subscribe((res) => {
      this._toastr.success(res.msg, "Success");
      this.getMedia();
      // this.emptyData();
    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });
  }

  Approve(data) {
    var _data = {
      "ss_id": data.ss_id,
      "statistics_id": data.statistics_id
    }
    debugger;
    this.adminService.GenericAddDataMethod(_data, 'approve_request_statistics').subscribe((res) => {

      this._toastr.success(res.message, "Success");
      this.getMedia();
      // this.emptyData();
    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });
  }

  getCount() {
    //var id = localStorage.getItem("statisticID");
    var params = {
      'region_id' : localStorage.getItem("regionId"),
      'sub_region_id': localStorage.getItem("subRegionId")
    };
    debugger; 
    this.adminService.GenericAddDataMethod(params,"count_requests").subscribe((res) => {
      debugger;
      if (res['data']!=undefined) {
        this.RequestData=res['data'][0];
      }
      console.log(res);
    },
      (error) => {
        //  this.auth.logout();
      });
  }

  EditData(item) {
    debugger;
    this.statisticsData = item;
  }
}
