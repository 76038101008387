import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  id: string;
  username: string;
  email: string;
  access_level: number;
  editUserForm: FormGroup;
  constructor(private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _toastr: ToastrService,
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router) {
      let token = this.storage.get('access_token');
      if(token == undefined || this.auth.decodeToken(token)['identity'][1] == 0){
        this.router.navigateByUrl('/home');
      }else{
        this.route.queryParams.subscribe(params => {
          this.id = params['id'];
          this.username = params['name'];
          this.email = params['email'];
          this.access_level = params['access_level'];
          this.editUserForm = this.fb.group({
            'email' : [this.email, [Validators.required, Validators.email]],
            'password' : [null, Validators.required],
            'username' : [this.username, Validators.required],
            'access_level' : [this.access_level, Validators.required],
          });
        });
      }
    
  }

  ngOnInit() {
   
  }

  editUser(formData: NgForm){

      this.auth.editUser(formData, this.id).subscribe((user) => {
        this.router.navigate(['/admin']);
      }, 
      (error) => {
        // this.auth.logout();
        this._toastr.error(error.error.error,"Error");
      });
  }

}
