import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatFormField } from '@angular/material/form-field';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AuthService } from '../auth/auth.service';
import { ConfirmModel } from '../model/confirm.model';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  ConfirmModel = new ConfirmModel();
  displayedColumns: string[] = ['userid', 'username', 'email', 'access_level', 'last_login', 'action'];
  dataSource : any;
  USER_DATA: PeriodicElement[] = [
  ];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private auth: AuthService,) { 
      let token = this.storage.get('access_token');
      if(token == undefined || this.auth.decodeToken(token)['identity'][1] == 0){
        this.router.navigateByUrl('/home');
      }else{
        this.auth.users().subscribe((res) => {
          this.USER_DATA = [];
          for(var i = 0; i< res['data'].length; i++){
            res['data'][i]['no'] = +i+1;
            if(res['data'][i]['access_level'] == 1)
              res['data'][i]['access'] = 'Admin';
            if(res['data'][i]['access_level'] == 0)
              res['data'][i]['access'] = 'User';
            this.USER_DATA.push(res['data'][i]);
          }
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.USER_DATA);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        (error) =>{
          this.auth.logout();
        });
      }
    }

  ngOnInit() {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  addUser(){
    this.router.navigate(['/add-user']);
  }

  editUser(id, name, email, access_level){
    this.router.navigate(['/edit-user'],{queryParams: {id: id, name : name, email: email, access_level: access_level}, skipLocationChange: true});
  }

  delUser(id){
    this.auth.delUser(id).subscribe((res) => {
      window.location.reload();
    }, 
    (error) => {
      this.auth.logout();
    })
  }

  applyFilter(filterValue: string){
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}

export interface PeriodicElement {
  user_id: number;
  username: string;
  email: string;
  access_level: number;
  last_login: string;
  no: number;
}

