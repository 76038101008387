import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import {Md5} from "md5-typescript";
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import {environment} from '../../environments/environment'
@Injectable({
  providedIn: 'root'
})

export class AdminService {

  baseUrl =  environment.URL;

  constructor(public http: HttpClient,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    ) { }

  
  headers = new HttpHeaders({ 
    'Content-Type' : 'application/json',
    'Authorization' : 'Bearer ' + this.storage.get('access_token') 
  });
  
  AddRegion(data){
   
    return this.http.post<any>( this.baseUrl + 'add_region', data, {
      headers: this.headers
    });
  }

  UpdateRegion(data){
   debugger;
    return this.http.post<any>( this.baseUrl + 'edit_region', data, {
      headers: this.headers
    });
  }


  AddStatistics(data){
   
    return this.http.post<any>( this.baseUrl + 'add_statistics', data, {
      headers: this.headers
    });
  }

UpdateStatistics(data){
   
    return this.http.post<any>( this.baseUrl + 'edit_statistics', data, {
      headers: this.headers
    });
  }

  AddAgencies(data){
   
    return this.http.post<any>( this.baseUrl + 'add_responsible_agencies', data, {
      headers: this.headers
    });
  }

  UpdateAgencies(data){
   
    return this.http.post<any>( this.baseUrl + 'edit_responsible_agencies', data, {
      headers: this.headers
    });
  }

  AddYearlyData(data){
   
    return this.http.post<any>( this.baseUrl + 'add_data', data, {
      headers: this.headers
    });
  }

  UpdateYearlyData(data){
   
    return this.http.post<any>( this.baseUrl + 'edit_data', data, {
      headers: this.headers
    });
  }
  AddResourcesData(data){
    return this.http.post<any>( this.baseUrl + 'add_additional_resources', data, {
      headers: this.headers
    });
  }

  UpdateResourcesData(data){
    debugger;
    return this.http.post<any>( this.baseUrl + 'edit_additional_resources', data, {
      headers: this.headers
    });
  }

  AddMediaData(data){
    return this.http.post<any>( this.baseUrl + 'add_media', data, {
      headers: this.headers
    });
  }

  UpdateMediaData(data){
    return this.http.post<any>( this.baseUrl + 'edit_media', data, {
      headers: this.headers
    });
  }


  AddSubRegion(data){
    return this.http.post<any>( this.baseUrl + 'add_sub_region', data, {
      headers: this.headers
    });
  }
  UpdateSubRegion(data){
    debugger;
    return this.http.post<any>( this.baseUrl + 'edit_sub_region', data, {
      headers: this.headers
    });
  }

  //#region 
  DeleteData(httpParams,endpoint){
      const httpOptions = {
        headers: this.headers,
        body: httpParams
        };
    return this.http.delete<any>( this.baseUrl + endpoint, httpOptions);
  }

  DeleteDataPost(data,endpoint){
    debugger;
    return this.http.post<any>( this.baseUrl + endpoint, data, {
      headers: this.headers
    });
  }


  GenericAddDataMethod(data,endpoint){
    return this.http.post<any>( this.baseUrl + endpoint, data, {
      headers: this.headers
    });
  }

  GenericGetDataMethod(data,endpoint){
    return this.http.get<any>( this.baseUrl + endpoint, {
      headers: this.headers
    });
  }
  //#endregion

  decodeToken(token: string = '') {
    


    const parts = token.split('.');
    if (parts.length !== 3) {
      return false;
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      return false;
    }
    return JSON.parse(decoded);
  }

  urlBase64Decode(str: string) {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
        case 0:
            break;
        case 2:
            output += '==';
            break;
        case 3:
            output += '=';
            break;
        default:
            // tslint:disable-next-line:no-string-throw
            throw 'Illegal base64url string!';
    }
    return decodeURIComponent((<any>window).escape(window.atob(output)));
  }

}
