import { Component, OnInit, Inject } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AuthService } from '../auth/auth.service';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ForgotComponent } from "../forgot/forgot.component";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  isError = 0;
  erroMsg = '';
  constructor(
    private modalService: NgbModal,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private auth: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private _location : Location
  ) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      'email' : ['', [Validators.required, Validators.email]],
      'password' : [null, Validators.required],
    });
  }

  dismiss(){
    this.modalService.dismissAll();
  }

  loginUser(formData: NgForm){
    this.auth.login(formData).subscribe((user) => {
      debugger;
      if(user['access_token']){
        this.storage.set('access_token', user['access_token']);
        this.modalService.dismissAll();
        this.isError = 0;
        window.location.href='/international';
      } else{
        this.isError = 1;
        this.storage.remove('access_token');
      }
    },
    (error) => {
      this.isError = 1;
      this.erroMsg = error['error']['error'];
    });
  }

  

  forgot(){
    this.modalService.dismissAll();
    this.modalService.open(ForgotComponent);
  }
}
