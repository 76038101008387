import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { AdminService } from '../services/admin.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
public CurrentYear = (new Date()).getFullYear();
addRegionForm: FormGroup;

constructor(private fb: FormBuilder,
  @Inject(LOCAL_STORAGE) private storage: StorageService,
  private adminService: AdminService,
  private _toastr: ToastrService) {
    
}

ngOnInit() {
 
  this.addRegionForm = this.fb.group({
    'name':['', Validators.required],
    'email' : ['', Validators.required],
    'subject' : ['', Validators.required],
    'message' : ['', Validators.required],
   
  });
  
    //this.addRegionForm.controls['region_id'].setValue(_regiondata.region_id);
  
}


InsertContactUS(formData: NgForm) {
  if(this.addRegionForm.valid) {
    this.adminService.GenericAddDataMethod(formData,"/contact").subscribe((res) => {
      debugger;
      this._toastr.success(res.msg,"Success");
      this.addRegionForm.controls['name'].setValue("");
      this.addRegionForm.controls['email'].setValue("");
      this.addRegionForm.controls['subject'].setValue("");
      this.addRegionForm.controls['message'].setValue("");
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  } else{
    this._toastr.error("All Fields are required","Error");
  }
  }
  



}
