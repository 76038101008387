import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  blog_detail: any = null;
  constructor(private adminService: AdminService, private router: Router, private _location: Location) {

  }
  ngOnInit() {
    this.getBlogs();
  }

  getBlogs() {
    var data = {
      blog_id:localStorage.getItem("blogId")
    }
    this.adminService.GenericAddDataMethod(data, "blog_post").subscribe((res) => {
      debugger;
      // this._toastr.success(res.msg,"Success");
      if (res != null) {
        this.blog_detail = res.data[0];
      }
    },
      (error) => {
        //  this._toastr.error("Error Occur while getting featured text","Error");
      });
  }

  backClicked() {
    this._location.back();
  }
}
