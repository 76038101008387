import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MapService } from 'src/app/services/map.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import {ConfirmModel} from '../../model/confirm.model';
@Component({
  selector: 'app-admin-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  addRegionForm: FormGroup;
  
  constructor(private fb: FormBuilder,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private auth: AuthService,
    private adminService: AdminService,
    private _toastr: ToastrService,
    private router: Router,) {
      let token = this.storage.get('access_token');
      if(token == undefined || this.auth.decodeToken(token)['identity'][1] == 0){
        this.router.navigateByUrl('/home');
      }
  }

  ngOnInit() {
   
    this.addRegionForm = this.fb.group({
      'host':['', Validators.required],
      'port' : ['', Validators.required],
      'user_name' : ['', Validators.required],
      'password' : ['', Validators.required],
    });
    this.getSettings();
  }
// get sub_region(): any {return this.addRegionForm.get('sub_region')};
  addRegion(formData: NgForm){
    debugger;
    if(this.addRegionForm.valid){
     //if(formData['region_id']=="") {
       this.InsertSettings(formData);
     //} else {
     // this.UpdateRegion(formData);
     //}
    } else {
      this._toastr.error("All Fields are required","Error");
    }
  }

  getSettings() {
    this.adminService.GenericGetDataMethod("","/show_email_settings").subscribe((res) => {
      debugger;
     // this._toastr.success(res.msg,"Success");
    if(res!=null) {
      this.addRegionForm.controls['host'].setValue(res.data[0].host);
      this.addRegionForm.controls['port'].setValue(res.data[0].port);
      this.addRegionForm.controls['user_name'].setValue(res.data[0].user_name);
    //  this.addRegionForm.controls['password'].setValue(res.data[0].password);
    }
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }

  InsertSettings(formData: NgForm) {
    this.adminService.GenericAddDataMethod(formData,"/email_settings").subscribe((res) => {
      this._toastr.success(res.msg,"Success");
    
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }

  UpdateRegion(formData: NgForm) {
    this.adminService.UpdateRegion(formData).subscribe((res) => {
      this._toastr.success(res.msg,"Success");
      // this.addRegionForm.controls['region'].setValue("");
      // this.addRegionForm.controls['color'].setValue("#CCCCCC");
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }
}
