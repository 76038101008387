import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hit-of-the-year',
  templateUrl: './hit-of-the-year-2017.component.html',
  styleUrls: ['../hit-of-the-year.component.scss']
})
export class HitOfTheYear2017Component implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
