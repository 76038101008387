import { BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BlogComponent } from './blog/blog.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login.component';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ForgotComponent } from './forgot/forgot.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material';
import {MatTreeModule,MatIconModule} from '@angular/material';
import {MatCardModule} from '@angular/material/card';
import { TreeModule } from 'angular-tree-component';
import { ColorPickerModule } from 'ngx-color-picker';
//#region Custom Components
import { AdminComponent } from './admin/admin.component';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import {PresentationComponent} from './presentation/presentation.component';
import {PolicyComponent} from './policy/policy.component';
import {ResourcesComponent} from './resources/resources.component';
import {DnaresourcesComponent} from './dnaresource/dnaresource.component';
import {BlogsComponent} from './blogs/blogs.component';
import {HitOfTheYearComponent} from './hit-of-the-year/hit-of-the-year.component';
import {HitOfTheYearAboutComponent} from './hit-of-the-year/about/hit-of-the-year-about.component';
import {HitOfTheYear2017Component} from './hit-of-the-year/2017/hit-of-the-year-2017.component';
import {HitOfTheYear2018Component} from './hit-of-the-year/2018/hit-of-the-year-2018.component';
import {HitOfTheYear2019Component} from './hit-of-the-year/2019/hit-of-the-year-2019.component';
import {HitOfTheYear2020Component} from './hit-of-the-year/2020/hit-of-the-year-2020.component';
import {HitOfTheYear2021Component} from './hit-of-the-year/2021/hit-of-the-year-2021.component';
import {HitOfTheYear2022Component} from './hit-of-the-year/2022/hit-of-the-year-2022.component';
import {HitOfTheYear2023Component} from './hit-of-the-year/2023/hit-of-the-year-2023.component';
import {ReportsComponent} from './reports/reports.component';
import {MapComponent} from './map/map.component';
import {RegionComponent} from './region/region.component';
import {RegionDataComponent} from './region-data/region-data.component';
import {ManageRegionComponent} from './admin/region/manage-region.component'; 
import {ManageSubRegionComponent} from './admin/sub-region/manage-sub-region.component';
import {AddRegionComponent} from './admin/add-region/add-region.component';
import {StatisticsComponent} from './admin/statistics/statistics.component';
import {AgenciesComponent} from './admin/agencies/agencies.component';
import {AdminResourcesComponent} from './admin/resources/resources.component';
import {MediaComponent} from './admin/media/media.component';
import {DataComponent} from './admin/data/data.component';
import {AddSubRegionComponent} from './admin/add-sub-region/add-sub-region.component';
//#endregion
import { DeviceDetectorModule } from 'ngx-device-detector'; 
import { ToastrModule } from 'ngx-toastr';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { SettingsComponent } from './admin/settings/settings.component';
import { FeaturedComponent } from './admin/featured/featured.component';
import { SubmissionComponent } from './admin/submission/submission.component';
import { SubmissionsComponent } from './submission/submission.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { MatTableExporterModule } from 'mat-table-exporter';
import { RequestsComponent } from './admin/requests/requests.component';
import { RequestsAdditionalResourcesComponent } from './admin/requests/additional_resources/requests-additonal-resources.component';
import { RequestsMediaComponent } from './admin/requests/media/requests-media.component';
import { RequestAgenciesComponent } from './admin/requests/responsible_agencies/request-agencies.component';
import { RequestDataComponent } from './admin/requests/data/request-data.component';
import { RequestStatisticsComponent } from './admin/requests/statistics/statistics.component';
import { ChildStatisticsComponent } from './admin/requests/statistics/child-statistics/child-statistics.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BlogComponent,
    LoginComponent,
    FooterComponent,
    HeaderComponent,
    ForgotComponent,
    AdminComponent,
    AddUserComponent,
    EditUserComponent,
    PresentationComponent,
    PolicyComponent,
    ResourcesComponent,
    DnaresourcesComponent,
    BlogsComponent,
    HitOfTheYearComponent,
    HitOfTheYear2017Component,
    HitOfTheYear2018Component,
    HitOfTheYear2019Component,
    HitOfTheYear2020Component,
    HitOfTheYear2021Component,
    HitOfTheYear2022Component,
    HitOfTheYear2023Component,
    HitOfTheYearAboutComponent,
    ReportsComponent,
    MapComponent,
    RegionComponent,
    RegionDataComponent,
    ManageRegionComponent,
    ManageSubRegionComponent,
    AddRegionComponent,
    StatisticsComponent,
    AgenciesComponent,
    AdminResourcesComponent,
    MediaComponent,
    DataComponent,
    AddSubRegionComponent,
    BlogDetailsComponent,
    SettingsComponent,
    FeaturedComponent,
    SubmissionComponent,
    SubmissionsComponent,
    RequestsComponent,
    RequestsAdditionalResourcesComponent,
    RequestsMediaComponent,
    RequestAgenciesComponent,
    RequestDataComponent,
    RequestStatisticsComponent,
    ChildStatisticsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    StorageServiceModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatTreeModule,
    MatIconModule,
    TreeModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    MatCardModule,
    ColorPickerModule,
    ToastrModule.forRoot(),
    AngularDateTimePickerModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' // set defaults here
    }),
    ChartsModule,
    MatTableExporterModule
  ],
  entryComponents:[
    LoginComponent,
    ForgotComponent
  ],
  providers: [ThemeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
