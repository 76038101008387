import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm  } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  addUserForm: FormGroup;
  constructor(private fb: FormBuilder,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _toastr: ToastrService,
    private auth: AuthService,
    private router: Router,) {
      let token = this.storage.get('access_token');
      if(token == undefined || this.auth.decodeToken(token)['identity'][1] == 0){
        this.router.navigateByUrl('/home');
      }
  }

  ngOnInit() {
    this.addUserForm = this.fb.group({
      'email' : ['', [Validators.required, Validators.email]],
      'password' : [null, Validators.required],
      'username' : [null, Validators.required],
      'access_level' : [null, Validators.required],
    });
  }

  addUser(formData: NgForm){
    //if(this.addUserForm.validator){
      this.auth.addUser(formData).subscribe((user) => {
        this.router.navigate(['/admin']);
      }, 
      (error) => {
        // this.auth.logout();
        this._toastr.error(error.error.error,"Error");
      });
  }
}
