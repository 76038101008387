import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MapService } from '../services/map.service';
import { LOCAL_STORAGE, StorageService, localStorageFactory } from 'ngx-webstorage-service';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
// import panzoom from "panzoom";
import * as $ from 'jquery';
declare var google: any;
declare var $: $;
declare var panzoom: any;
@Component({
  selector: 'app-reports',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  @ViewChild('regions_div', { static: false }) scene: ElementRef;
  panZoomController;
  zoomLevels: number[];

  currentZoomLevel: number;
  public regiondata: any;

  constructor(private _MapService: MapService, private router: Router,private deviceService: DeviceDetectorService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private auth: AuthService) {
    let token = this.storage.get('access_token');
    if (token == undefined || token == null) {
      this.router.navigateByUrl('/home');
    }

  }
  ngOnInit() {

  }

  zoom() {
    const isSmooth = false;
    const scale = this.currentZoomLevel;


    if (scale) {
      const transform = this.panZoomController.getTransform();
      const deltaX = transform.x;
      const deltaY = transform.y;
      const offsetX = scale + deltaX;
      const offsetY = scale + deltaY;

      if (isSmooth) {
        this.panZoomController.smoothZoom(0, 0, scale);
      } else {
        this.panZoomController.zoomTo(offsetX, offsetY, scale);
      }
    }

  }

  zoomToggle(zoomIn: boolean) {
    const idx = this.zoomLevels.indexOf(this.currentZoomLevel);
    if (zoomIn) {
      if (typeof this.zoomLevels[idx + 1] !== 'undefined') {
        this.currentZoomLevel = this.zoomLevels[idx + 1];
      }
    } else {
      if (typeof this.zoomLevels[idx - 1] !== 'undefined') {
        this.currentZoomLevel = this.zoomLevels[idx - 1];
      }
    }
    if (this.currentZoomLevel === 1) {
      this.panZoomController.moveTo(0, 0);
      this.panZoomController.zoomAbs(0, 0, 1);
    } else {
      this.zoom();
    }
  }
  ngAfterViewInit() {



    this._MapService.getRegions().subscribe((regions) => {
      let that = this;
      debugger;
      google.charts.load('current', {
        'packages': ['geochart'],
        // Note: you will need to get a mapsApiKey for your project.
        // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
        'mapsApiKey': 'AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY'
      });
      google.charts.setOnLoadCallback(drawRegionsMap);


      function drawRegionsMap() {
        var ChartData = [];
        var regionData = regions['data'];
        var colorArray = [];
        var i = 0;
        regionData.forEach(element => {
          i=i+1;
          ChartData.push(
            [element.region, i, ''
            ]

          );
          colorArray.push(element.color);
        });
        console.log(ChartData);
        var data = google.visualization.arrayToDataTable([]);
        data.addColumn('string', 'Country');
        data.addColumn('number', 'Popularity');
        data.addColumn({ type: 'string', role: 'tooltip' })
        data.addRows(ChartData);
        var options = {
          backgroundColor: 'transparent',
          colorAxis: { colors: colorArray },
          is3D: true,
          legend: 'none',
          tooltip: {
            isHtml: true
          }
        };

        // var options = {
        //   region: 'US',
        //   displayMode: 'markers',
        //   resolution: 'provinces',
        //   backgroundColor: 'transparent',
        //   colorAxis: { colors: colorArray },
        //   is3D: true,
        //   legend: 'none',
        //   tooltip: {
        //     isHtml: true
        //   }
        // };
        var chart = new google.visualization.GeoChart(document.getElementById('regions_div'));
        function myClickHandler() {
          var selection = chart.getSelection();
          debugger;
          var message = '';
          for (var i = 0; i < selection.length; i++) {
            var item = selection[i];
            if (item.row != null && item.column != null) {
              message += item.row ;
            } else if (item.row != null) {
              message += item.row ;
            } else if (item.column != null) {
              message +=  item.column ;
            }
          }
          if (message == '') {
            message = 'nothing';
           // alert('You selected ' + message);
          } else {
            var selectedRegion = regions['data'][message];
            localStorage.setItem("regionName",selectedRegion.region);
            localStorage.setItem("regionId",selectedRegion.region_id);
            if(selectedRegion.sub_region) {
             
              that.router.navigateByUrl('/region');
            } else {
              localStorage.setItem("subRegionId","");
              that.router.navigateByUrl('/region-data');
            }
            
          }
          
        }

        google.visualization.events.addListener(chart, 'select', myClickHandler);
        chart.draw(data, options);
      }

      this.zoomLevels = [0.1, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3];
      this.currentZoomLevel = this.zoomLevels[4];
      // panzoom(document.querySelector('#regions_div'));
      const isDesktopDevice = this.deviceService.isDesktop();
      //alert(isDesktopDevice);
      if(isDesktopDevice) {
        this.panZoomController = panzoom(this.scene.nativeElement, {
          minZoom: 1
        });
  
      }
     
    },
      (error) => {

      });

  }


}

