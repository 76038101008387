import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, NgForm } from '@angular/forms';
import { AdminService } from '../services/admin.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.scss']
})
export class SubmissionsComponent implements OnInit {
  addRegionForm: FormGroup;
  links = "";
  submitted = false;
  constructor(private fb: FormBuilder, private adminService: AdminService,
    private _toastr: ToastrService) { }

  ngOnInit() {
    this.addRegionForm = this.fb.group({
      'name': ['', Validators.required],
      'agency': ['',Validators.required],
      'email': ['',[Validators.required,Validators.email]],
      'region': ['',Validators.required],
      'message': ['',Validators.required]
    });
  }
  get f() { return this.addRegionForm.controls; } 

  submitForm(formData: NgForm) {
    debugger;
    //this.addRegionForm.get('links').setValidators(null);
formData['links'] = this.links;
    if(!this.addRegionForm.invalid) {
      this.submitted =false;
      this.adminService.GenericAddDataMethod(formData,"submit_submission").subscribe((res) => {
        this._toastr.success(res.message, "Success");
        this.emptyData();
      },
        (error) => {
          this._toastr.error(error.error.error, "Error");
        });
    } else {
      this.submitted =true;
    }
    
  }

  emptyData () {
    this.addRegionForm.controls['name'].setValue("");
      this.addRegionForm.controls['agency'].setValue("");
      this.addRegionForm.controls['email'].setValue("");
      this.addRegionForm.controls['region'].setValue("");
     // this.addRegionForm.controls['links'].setValue("");
      this.addRegionForm.controls['message'].setValue("");
      this.links = "";
  }

}
