import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { BlogComponent } from './blog/blog.component';
import { AdminComponent } from './admin/admin.component';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import {PresentationComponent} from './presentation/presentation.component';
import {PolicyComponent} from './policy/policy.component';
import {ResourcesComponent} from './resources/resources.component';
import {DnaresourcesComponent} from './dnaresource/dnaresource.component';
import {BlogsComponent} from './blogs/blogs.component';
import {HitOfTheYearComponent} from './hit-of-the-year/hit-of-the-year.component';
import {HitOfTheYearAboutComponent} from './hit-of-the-year/about/hit-of-the-year-about.component';
import {HitOfTheYear2017Component} from './hit-of-the-year/2017/hit-of-the-year-2017.component';
import {HitOfTheYear2018Component} from './hit-of-the-year/2018/hit-of-the-year-2018.component';
import {HitOfTheYear2019Component} from './hit-of-the-year/2019/hit-of-the-year-2019.component';
import {HitOfTheYear2020Component} from './hit-of-the-year/2020/hit-of-the-year-2020.component';
import {HitOfTheYear2021Component} from './hit-of-the-year/2021/hit-of-the-year-2021.component';
import {HitOfTheYear2022Component} from './hit-of-the-year/2022/hit-of-the-year-2022.component';
import {HitOfTheYear2023Component} from './hit-of-the-year/2023/hit-of-the-year-2023.component';
import {ReportsComponent} from './reports/reports.component';
import {MapComponent} from './map/map.component';
import {RegionComponent} from './region/region.component';
import {RegionDataComponent} from './region-data/region-data.component';
import {ManageRegionComponent} from './admin/region/manage-region.component';
import {ManageSubRegionComponent} from './admin/sub-region/manage-sub-region.component';
import {AddRegionComponent} from './admin/add-region/add-region.component';
import {StatisticsComponent} from './admin/statistics/statistics.component';
import {AgenciesComponent} from './admin/agencies/agencies.component';
import {AdminResourcesComponent} from './admin/resources/resources.component';
import {MediaComponent} from './admin/media/media.component';
import {DataComponent} from './admin/data/data.component';
import {AddSubRegionComponent} from './admin/add-sub-region/add-sub-region.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { SettingsComponent } from './admin/settings/settings.component';
import { FeaturedComponent } from './admin/featured/featured.component';
import { SubmissionComponent } from './admin/submission/submission.component';
import { SubmissionsComponent } from './submission/submission.component';
import { RequestsComponent } from './admin/requests/requests.component';
import { RequestsAdditionalResourcesComponent } from './admin/requests/additional_resources/requests-additonal-resources.component';
import { RequestsMediaComponent } from './admin/requests/media/requests-media.component';
import { RequestAgenciesComponent } from './admin/requests/responsible_agencies/request-agencies.component';
import { RequestDataComponent } from './admin/requests/data/request-data.component';
import { RequestStatisticsComponent } from './admin/requests/statistics/statistics.component';
const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component : HomeComponent},
  { path: 'blog', component : BlogsComponent},
  { path: 'admin', component : AdminComponent},
  { path: 'add-user', component : AddUserComponent},
  { path: 'edit-user', component: EditUserComponent},
  {path: 'presentation', component: PresentationComponent},
  {path: 'policy', component: PolicyComponent},
  {path: 'resources', component: ResourcesComponent},
  {path: 'dnaresource', component: DnaresourcesComponent},
  {path: 'hitoftheyear', component: HitOfTheYearComponent},
  {path: 'hitoftheyear-about', component: HitOfTheYearAboutComponent},
  {path: 'hitoftheyear-2017', component: HitOfTheYear2017Component},
  {path: 'hitoftheyear-2018', component: HitOfTheYear2018Component},
  {path: 'hitoftheyear-2019', component: HitOfTheYear2019Component},
  {path: 'hitoftheyear-2020', component: HitOfTheYear2020Component},
  {path: 'hitoftheyear-2021', component: HitOfTheYear2021Component},
  {path: 'hitoftheyear-2022', component: HitOfTheYear2022Component},
  {path: 'hitoftheyear-2023', component: HitOfTheYear2023Component},
  {path: 'reports', component: ReportsComponent},
  {path: 'international', component: MapComponent},
  {path: 'region', component: RegionComponent},
  {path: 'region-data',component: RegionDataComponent},
  {path: 'admin-region',component: ManageRegionComponent},
  {path: 'admin-sub-region',component: ManageSubRegionComponent},
  {path: 'admin-manage-region',component: AddRegionComponent},
  {path: 'admin-statistics',component: StatisticsComponent},
  {path: 'admin-agencies',component: AgenciesComponent},
  {path: 'admin-yearly-data',component: DataComponent},
  {path: 'admin-resources',component: AdminResourcesComponent},
  {path: 'admin-media',component: MediaComponent},
  {path: 'admin-manage-sub-region',component: AddSubRegionComponent},
  {path: 'blog-details',component:BlogDetailsComponent},
  {path: 'admin-settings', component: SettingsComponent},
  {path: 'admin-featured-text',component: FeaturedComponent},
  {path: 'admin-submissions',component: SubmissionComponent},
  {path: 'submission',component: SubmissionsComponent},
  {path: 'requests',component: RequestsComponent},
  {path: 'request-resources',component: RequestsAdditionalResourcesComponent},
  {path: 'request-media',component:RequestsMediaComponent},
  {path: 'request-agencies',component: RequestAgenciesComponent},
  {path: 'request-data',component:RequestDataComponent},
  {path: 'request-statistics','component':RequestStatisticsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
