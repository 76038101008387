import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MapService } from 'src/app/services/map.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import {ConfirmModel} from '../../model/confirm.model';
@Component({
  selector: 'app-add-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {
  ConfirmModel=new ConfirmModel();
  displayedColumns: string[] = ['display_text', 'link',  'Action'];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  
  addRegionForm: FormGroup;
  media_id= 0;
  mediaArray = [
    // {display_text:"",link:"",media_id:""}
  ]
  constructor(private fb: FormBuilder,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _location: Location,
    private _MapService: MapService,
    private adminService: AdminService,
    private _toastr: ToastrService,
    private auth: AuthService,
    private router: Router, ) {
    let token = this.storage.get('access_token');
    if (token == undefined || this.auth.decodeToken(token)['identity'][1] == 0) {
      this.router.navigateByUrl('/home');
    } else {
      this.getMediaData();
    }
  }

  getMediaData() {
    var id = localStorage.getItem("statisticID");
    this._MapService.getMedia(id).subscribe((res) => {
      debugger;
      if (res['data']!=undefined) {
        this.mediaArray = res['data'];

        this.dataSource = new MatTableDataSource(this.mediaArray);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      console.log(res);
    },
      (error) => {
        // this.auth.logout();
      });
  }
  ngOnInit() {
    this.addRegionForm = this.fb.group({
      'statistics_id': localStorage.getItem('statisticID'),
      'display_text': [''],
      'link': ['']
    });
  }

  addMedia() {
    this.mediaArray.push({ display_text: "", link: "", media_id: "" });
  }

  backClicked() {
    this._location.back();
  }
  addNewMedia(formData: NgForm) {
   if(this.media_id==0) {
     this.InsertMedia(formData);
   } else {
     this.UpdateMedia(formData);
   }
  }

  InsertMedia(formData: NgForm) {
    debugger;
    this.adminService.AddMediaData(formData).subscribe((res) => {
      this._toastr.success(res.msg, "Success");
      this.getMediaData();
      this.emptyData();
    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });
  }

  UpdateMedia(formData: NgForm) {
    debugger;
    formData['media_id'] = this.media_id;
    this.adminService.UpdateMediaData(formData).subscribe((res) => {
      this._toastr.success(res.msg, "Success");
      this.getMediaData();
     // this.emptyData(); 
    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });    
  }

  emptyData () {
    this.media_id = 0;
    this.addRegionForm.controls['display_text'].setValue("");
      this.addRegionForm.controls['link'].setValue("");
  }

  EditData(data) {
    this.media_id = data.media_id;
    this.addRegionForm.controls['display_text'].setValue(data.display_text);
      this.addRegionForm.controls['link'].setValue(data.link);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  RemoveData(data) {
    var _data = {
      "media_id": data.media_id
    }
    debugger;
    this.adminService.DeleteData(_data,'delete_media').subscribe((res) => {
      this._toastr.success(res.msg,"Success");
      this.getMediaData();
     // this.emptyData();
      }, 
      (error) =>{
        this._toastr.error(error.error.error,"Error");
        });
  }
}
