import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthService } from '../../../../auth/auth.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MapService } from 'src/app/services/map.service';
import { Location } from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModel } from '../../../../model/confirm.model';

@Component({
  selector: 'app-statistics-child',
  templateUrl: './child-statistics.component.html',
  styleUrls: ['./child-statistics.component.scss']
})
export class ChildStatisticsComponent implements OnInit {
  @Input('statisticsData') statisticsData: any;
  ConfirmModel = new ConfirmModel();

  addRegionForm: FormGroup;
disable = false;
  isedit = false;
 
  constructor(private fb: FormBuilder,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _MapService: MapService,
    private auth: AuthService,
    private _location: Location,
    private adminService: AdminService,
    private _toastr: ToastrService,
    private router: Router, ) {
      this.addRegionForm = this.fb.group({
        //'id' : null,
        'region_id': localStorage.getItem('regionId'),
        'sub_region_id': localStorage.getItem('subRegionId'),
        'image': [null, Validators.required],
        'current_status': [null, Validators.required],
        'software_used': [null, Validators.required],
        'region_information': [null, Validators.required],
        'population': [null, Validators.required],
        'gdp': [null, Validators.required],
        'gdp_per_capita': [null, Validators.required],
        'government': [null, Validators.required],
        'convicted_testing': [null, Validators.required],
        'arrestee_testing': [null, Validators.required],
        'removal_convicted': [null, Validators.required],
        'removal_arrestee': [null, Validators.required],
        'convicted_sample': [null, Validators.required],
        'arrestee_sample': [null, Validators.required],
        'ct_switch': [null, Validators.required],
        'at_switch': [null, Validators.required],
        'rc_switch': [null, Validators.required],
        'ra_switch': [null, Validators.required],
        'cs_switch': [null, Validators.required],
        'as_switch': [null, Validators.required],
        'status': [null, Validators.required],
        'ss_id': null
        // 'data' : [null, Validators.required],
        // 'agenciesresources' : [null, Validators.required],
        // 'resources' : [null, Validators.required],
        // 'media' : [null, Validators.required]

      });
     //this.getStatistics();
  }

  getStatistics() {
    debugger;
    if (this.statisticsData['statistics_id'] > 0) {
        this.disable=false;
        localStorage.setItem("statisticID", this.statisticsData['statistics_id']);
        this.addRegionForm.controls['ss_id'].setValue(this.statisticsData['ss_id']);
        this.addRegionForm.controls['image'].setValue(this.statisticsData['image']);
        this.addRegionForm.controls['current_status'].setValue(this.statisticsData['current_status']);
        this.addRegionForm.controls['software_used'].setValue(this.statisticsData['software_used']);
        this.addRegionForm.controls['region_information'].setValue(this.statisticsData['region_information']);
        this.addRegionForm.controls['population'].setValue(this.statisticsData['population']);
        this.addRegionForm.controls['gdp'].setValue(this.statisticsData['gdp']);
        this.addRegionForm.controls['gdp_per_capita'].setValue(this.statisticsData['gdp_per_capita']);
        this.addRegionForm.controls['government'].setValue(this.statisticsData['government']);
        this.addRegionForm.controls['convicted_testing'].setValue(this.statisticsData['convicted_testing']);
        this.addRegionForm.controls['arrestee_testing'].setValue(this.statisticsData['arrestee_testing']);
        this.addRegionForm.controls['removal_convicted'].setValue(this.statisticsData['removal_convicted']);
        this.addRegionForm.controls['removal_arrestee'].setValue(this.statisticsData['removal_arrestee']);
        this.addRegionForm.controls['convicted_sample'].setValue(this.statisticsData['convicted_sample']);
        this.addRegionForm.controls['arrestee_sample'].setValue(this.statisticsData['arrestee_sample']);

        this.addRegionForm.controls['ct_switch'].setValue(this.statisticsData['ct_switch']);
        this.addRegionForm.controls['at_switch'].setValue(this.statisticsData['at_switch']);
        this.addRegionForm.controls['rc_switch'].setValue(this.statisticsData['rc_switch']);
        this.addRegionForm.controls['ra_switch'].setValue(this.statisticsData['ra_switch']);
        this.addRegionForm.controls['cs_switch'].setValue(this.statisticsData['cs_switch']);
        this.addRegionForm.controls['as_switch'].setValue(this.statisticsData['as_switch']);
        this.addRegionForm.controls["status"].setValue(this.statisticsData['status']);
        this.isedit = true;
      }
  }
  ngOnInit() {
    this.getStatistics();
  }
  // get sub_region(): any {return this.addRegionForm.get('sub_region')};
  addstatistics(formData: NgForm) {
    this.UpdateStatistics(formData);
  }
 
  

  UpdateStatistics(formData: NgForm) {
    debugger;
    this.adminService.GenericAddDataMethod(formData,"edit_request_statistics").subscribe((res) => {
      this._toastr.success(res.message, "Success");

    },
      (error) => {
        this._toastr.error(error.error.error, "Error");
      });
  }

}
